import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import Icon from "@mdi/react";
import {
  mdiMenu,
  mdiAccount,
  mdiTrophy,
  mdiMedal,
  mdiCog,
  mdiLeaf,
  mdiChevronRight,
  mdiClose,
} from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";
import Navbar from "../components/Navbar";
import Button from "../components/Button";

function AccountPage() {
  const { userData, updateUserData, darkMode, token } = useLogin();
  const navigate = useNavigate();
  const [showBadgeModal, setShowBadgeModal] = useState(false);
  const [showTitleModal, setShowTitleModal] = useState(false);
  const [availableTitles, setAvailableTitles] = useState([]);

  useEffect(() => {
    // Filter userRewards to get available titles
    const titles = userData.userRewards
      .filter(
        (reward) =>
          reward.rewardType === "title" || reward.rewardType === "Title"
      )
      .map((reward) => ({
        id: reward.rewardID,
        text: reward.rewardText || reward.rewardTitle,
      }));
    setAvailableTitles(titles);
  }, [userData.userRewards]);

  const settingsIcon = (
    <Icon
      path={mdiCog}
      size={1.5}
      className="text-white cursor-pointer"
      aria-label="Settings"
      onClick={() => navigate("/account-settings")}
    />
  );

  const menuIcon = (
    <Icon
      path={mdiMenu}
      size={1.5}
      className="text-white cursor-pointer"
      aria-label="Menu"
    />
  );

  const currentLevel = Math.floor(userData.greenScore / 1000);
  const nextLevel = currentLevel + 1;
  const progressToNextLevel = (userData.greenScore % 1000) / 10;

  const handleBadgeChange = async (newBadge) => {
    try {
      await updateUserData({ ...userData, badge: newBadge });
      setShowBadgeModal(false);
    } catch (error) {
      console.error("Failed to update badge:", error);
    }
  };

  const handleTitleChange = async (titleId, titleText) => {
    try {
      console.log(titleId, titleText);
      const response = await fetch("https://api.qmb.org.uk/customise", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          badge: null,
          title: titleId,
        }),
      });

      console.log(response);

      if (response.ok) {
        setShowTitleModal(false);
      } else {
        throw new Error("Failed to update title");
      }
    } catch (error) {
      console.error("Failed to update title:", error);
      // You might want to show an error message to the user here
    }
  };

  const calculateTitle = (id) => {
    if (!userData.userRewards) {
      return "No Title";
    }
    const reward = userData.userRewards.find(
      (reward) => reward.rewardId === id
    );
    return reward ? reward.rewardText : "No Title";
  };

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900" : "bg-gray-100"
      } font-righteous pb-24 relative`}
    >
      <div className="absolute top-0 left-0 right-0 h-1/4 bg-[#319D32] rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)] z-0"></div>
      <div className="relative z-10 container mx-auto px-4 py-4">
        <TopBar leftIcon={[menuIcon]} rightIcons={[settingsIcon]} />
      </div>

      <div className="relative z-10 container mx-auto px-4 mt-8">
        <div
          className={`${
            darkMode ? "bg-gray-800" : "bg-white"
          } rounded-[30px] shadow-md p-6 mb-6`}
        >
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center">
              <Icon
                path={mdiAccount}
                size={3}
                className={`${
                  darkMode ? "text-gray-400" : "text-gray-500"
                } mr-4`}
              />
              <div>
                <h2
                  className={`text-2xl font-bold ${
                    darkMode ? "text-gray-200" : "text-gray-800"
                  }`}
                >
                  {userData.name}
                </h2>
                <p
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-500"
                  }`}
                >
                  Account Number: {userData.accountNumber}
                </p>
              </div>
            </div>
          </div>

          <div
            className={`${
              darkMode ? "bg-green-900" : "bg-green-50"
            } rounded-lg p-6 mb-6`}
          >
            <div className="flex items-center justify-between mb-4">
              <div>
                <p
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-500"
                  }`}
                >
                  Current Level
                </p>
                <p className="text-4xl font-bold text-green-600">
                  {currentLevel}
                </p>
              </div>
              <Icon path={mdiLeaf} size={3} className="text-green-500" />
              <div className="text-right">
                <p
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-500"
                  }`}
                >
                  Green Score
                </p>
                <p className="text-4xl font-bold text-green-600">
                  {userData.greenScore}
                </p>
              </div>
            </div>
            <div className="mb-2">
              <div
                className={`flex justify-between text-sm ${
                  darkMode ? "text-gray-400" : "text-gray-600"
                } mb-1`}
              >
                <span>Progress to Level {nextLevel}</span>
                <span>{progressToNextLevel.toFixed(1)}%</span>
              </div>
              <div
                className={`w-full ${
                  darkMode ? "bg-gray-700" : "bg-gray-200"
                } rounded-full h-4`}
              >
                <div
                  className="bg-green-600 h-4 rounded-full transition-all duration-500 ease-in-out"
                  style={{ width: `${progressToNextLevel}%` }}
                ></div>
              </div>
            </div>
            <p
              className={`text-sm ${
                darkMode ? "text-gray-400" : "text-gray-600"
              } text-center mt-2`}
            >
              {1000 - (userData.greenScore % 1000)} points to next level
            </p>
          </div>

          <div className="space-y-4">
            <div
              className={`flex items-center justify-between p-4 ${
                darkMode ? "bg-gray-700" : "bg-gray-50"
              } rounded-lg`}
            >
              <div className="flex items-center">
                <Icon
                  path={mdiAccount}
                  size={1.5}
                  className={`${
                    darkMode ? "text-gray-400" : "text-gray-500"
                  } mr-4`}
                />
                <div>
                  <p
                    className={`text-sm ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    Account Type
                  </p>
                  <p
                    className={`text-lg font-bold ${
                      darkMode ? "text-gray-200" : "text-gray-800"
                    }`}
                  >
                    {userData.accountType}
                  </p>
                </div>
              </div>
            </div>
            <div
              className={`flex items-center justify-between p-4 ${
                darkMode ? "bg-gray-700" : "bg-gray-50"
              } rounded-lg cursor-pointer`}
              onClick={() => setShowBadgeModal(true)}
            >
              <div className="flex items-center">
                <Icon
                  path={mdiTrophy}
                  size={1.5}
                  className="text-yellow-500 mr-4"
                />
                <div>
                  <p
                    className={`text-sm ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    Badge
                  </p>
                  <p
                    className={`text-lg font-bold ${
                      darkMode ? "text-gray-200" : "text-gray-800"
                    }`}
                  >
                    {userData.badge}
                  </p>
                </div>
              </div>
              <Icon
                path={mdiChevronRight}
                size={1}
                className={`${darkMode ? "text-gray-400" : "text-gray-500"}`}
              />
            </div>
            <div
              className={`flex items-center justify-between p-4 ${
                darkMode ? "bg-gray-700" : "bg-gray-50"
              } rounded-lg cursor-pointer`}
              onClick={() => setShowTitleModal(true)}
            >
              <div className="flex items-center">
                <Icon
                  path={mdiMedal}
                  size={1.5}
                  className="text-blue-500 mr-4"
                />
                <div>
                  <p
                    className={`text-sm ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    Title
                  </p>
                  <p
                    className={`text-lg font-bold ${
                      darkMode ? "text-gray-200" : "text-gray-800"
                    }`}
                  >
                    {userData.title === null
                      ? "No Title"
                      : userData.userRewards.find(
                          (reward) => reward.rewardType === "title"
                        )?.rewardText || "No Title"}
                  </p>
                </div>
              </div>
              <Icon
                path={mdiChevronRight}
                size={1}
                className={`${darkMode ? "text-gray-400" : "text-gray-500"}`}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Badge Modal */}
      {showBadgeModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            className={`${
              darkMode ? "bg-gray-800" : "bg-white"
            } rounded-lg p-6 w-full max-w-sm`}
          >
            <div className="flex justify-between items-center mb-4">
              <h2
                className={`text-xl font-bold ${
                  darkMode ? "text-gray-200" : "text-gray-800"
                }`}
              >
                Change Badge
              </h2>
              <button onClick={() => setShowBadgeModal(false)}>
                <Icon
                  path={mdiClose}
                  size={1}
                  className={darkMode ? "text-gray-400" : "text-gray-600"}
                />
              </button>
            </div>
            <div className="space-y-2">
              {["Eco Warrior", "Green Guardian", "Sustainability Champion"].map(
                (badge) => (
                  <button
                    key={badge}
                    className={`w-full p-2 text-left ${
                      darkMode
                        ? "hover:bg-gray-700 text-gray-200"
                        : "hover:bg-gray-100 text-gray-800"
                    } rounded`}
                    onClick={() => handleBadgeChange(badge)}
                  >
                    {badge}
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      )}

      {/* Title Modal */}
      {showTitleModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            className={`${
              darkMode ? "bg-gray-800" : "bg-white"
            } rounded-lg p-6 w-full max-w-sm`}
          >
            <div className="flex justify-between items-center mb-4">
              <h2
                className={`text-xl font-bold ${
                  darkMode ? "text-gray-200" : "text-gray-800"
                }`}
              >
                Change Title
              </h2>
              <button onClick={() => setShowTitleModal(false)}>
                <Icon
                  path={mdiClose}
                  size={1}
                  className={darkMode ? "text-gray-400" : "text-gray-600"}
                />
              </button>
            </div>
            <div className="space-y-2">
              {availableTitles.map((title) => (
                <button
                  key={title.id}
                  className={`w-full p-2 text-left ${
                    darkMode
                      ? "hover:bg-gray-700 text-gray-200"
                      : "hover:bg-gray-100 text-gray-800"
                  } rounded`}
                  onClick={() => handleTitleChange(title.id, title.text)}
                >
                  {title.text}
                </button>
              ))}
              <Button
                onClick={() => handleTitleChange(null, null)}
                className="w-full text-left"
                label="No Title"
                variant="primary"
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      )}

      <Navbar />
    </div>
  );
}

export default AccountPage;
