import React, { createContext, useState, useContext } from "react";

const RegistrationContext = createContext();

export const useRegistration = () => useContext(RegistrationContext);

export const RegistrationProvider = ({ children }) => {
  const [accountNumber, setAccountNumber] = useState(null);

  return (
    <RegistrationContext.Provider value={{ accountNumber, setAccountNumber }}>
      {children}
    </RegistrationContext.Provider>
  );
};
