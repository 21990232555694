import React from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiHome, mdiBank, mdiCart, mdiAccount, mdiLeaf, mdiCog, mdiTrophy, mdiAccountGroup } from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";

function Navbar() {
  const location = useLocation();
  const { darkMode, isAdmin } = useLogin();

  const userNavItems = [
    { path: "/home", icon: mdiHome, label: "Home" },
    { path: "/bank", icon: mdiBank, label: "Bank" },
    { path: "/green-score", icon: mdiLeaf, label: "Green " },
    { path: "/shop", icon: mdiCart, label: "Shop" },
    { path: "/account", icon: mdiAccount, label: "Account" },
  ];

  const adminNavItems = [
    { path: "/admin-dashboard", icon: mdiHome, label: "Dashboard" },
    { path: "/admin/rewards", icon: mdiTrophy, label: "Rewards" },
    { path: "/admin/challenges", icon: mdiLeaf, label: "Challenges" },
    { path: "/admin/users", icon: mdiAccountGroup, label: "Users" },
    { path: "/admin/settings", icon: mdiCog, label: "Settings" },
  ];

  const navItems = isAdmin ? adminNavItems : userNavItems;

  return (
    <nav className={`fixed bottom-0 left-0 right-0 ${darkMode ? 'bg-gray-800' : 'bg-white'} pt-2 pb-2 font-righteous shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1),_0_-2px_4px_-1px_rgba(0,0,0,0.06)] z-50`}>
      <div className="flex justify-between items-center px-4">
        {navItems.map((item) => {
          const isActive = location.pathname === item.path;
          return (
            <Link
              key={item.path}
              to={item.path}
              className="flex flex-col items-center w-1/5"
            >
              <Icon
                path={item.icon}
                size={1.2}
                className={`transition-all duration-300 ${
                  isActive 
                    ? "text-[#319D32]" 
                    : darkMode 
                      ? "text-gray-400" 
                      : "text-gray-500"
                }`}
              />
              <span
                className={`text-xs text-center truncate w-full transition-all duration-300 ${
                  isActive
                    ? "opacity-100 max-h-6 text-[#319D32]"
                    : "opacity-0 max-h-0"
                } ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}
              >
                {item.label}
              </span>
            </Link>
          );
        })}
      </div>
    </nav>
  );
}

export default Navbar;
