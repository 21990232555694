import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../contexts/LoginContext";
import TopBar from "../components/TopBar";
import Button from "../components/Button";
import InputField from "../components/InputField";
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiTrophy, mdiClose, mdiPlus } from "@mdi/js";
import { showErrorToast, showSuccessToast } from "../components/Toast";
import DateInputField from "../components/DateInputField";
import { useCreateChallenge } from "../contexts/CreateChallengeContext";

function AdminAddChallengePage() {
  const { darkMode, token } = useLogin();
  const navigate = useNavigate();
  const {
    formData,
    updateFormData,
    resetFormData,
    showRewardModal,
    setShowRewardModal,
  } = useCreateChallenge();
  const [rewards, setRewards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchRewards();
  }, []);

  const fetchRewards = async () => {
    try {
      const response = await fetch("https://api.qmb.org.uk/getRewards", {
        headers: { Authorization: token },
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setRewards(data);
      } else {
        showErrorToast("Failed to fetch rewards");
      }
    } catch (error) {
      showErrorToast("An error occurred while fetching rewards");
    }
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "rewardID" || e.target.type === "number") {
      value = Number(value);
    }
    if (e.target.name === "type") {
      value = "numberOfItems";
    }
    updateFormData({ [e.target.name]: value });
  };

  const formatDateForSubmission = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };

  const validateForm = () => {
    if (
      !formData.type.trim() ||
      !formData.title.trim() ||
      !formData.description.trim() ||
      !formData.startDate ||
      !formData.endDate ||
      !formData.category.trim() ||
      !formData.rewardID ||
      formData.cost < 0 ||
      formData.goalAmount <= 0
    ) {
      showErrorToast("Please fill in all fields correctly");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      // Create a new object with formatted dates
      const submissionData = {
        ...formData,
        startDate: formatDateForSubmission(formData.startDate),
        endDate: formatDateForSubmission(formData.endDate),
      };

      console.log(
        "Sending request with data:",
        JSON.stringify(submissionData, null, 2)
      );
      console.log("Authorization token:", token);

      const response = await fetch("https://api.qmb.org.uk/createChallenge", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(submissionData),
      });

      console.log("Response status:", response.status);
      console.log(
        "Response headers:",
        JSON.stringify(Object.fromEntries(response.headers), null, 2)
      );

      const responseText = await response.text();
      console.log("Raw response body:", responseText);

      let data;
      if (responseText) {
        try {
          data = JSON.parse(responseText);
        } catch (parseError) {
          console.error("Error parsing response JSON:", parseError);
          data = { message: responseText };
        }
      } else {
        data = { message: "No response body" };
      }

      if (!response.ok) {
        throw new Error(
          `HTTP error! status: ${response.status}, message: ${
            data.message || "Unknown error"
          }`
        );
      }

      showSuccessToast("Challenge added successfully");
      resetFormData();
      navigate("/admin/challenges");
    } catch (error) {
      console.error("Error adding challenge:", error);
      showErrorToast(`Failed to add challenge: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const backIcon = (
    <Icon
      path={mdiArrowLeft}
      size={1.5}
      className="text-white cursor-pointer"
      onClick={() => navigate(-1)}
    />
  );

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900" : "bg-gray-100"
      } font-righteous pb-24 relative`}
    >
      <div className="absolute top-0 left-0 right-0 h-[20vh] bg-[#319D32] rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)] z-0"></div>
      <div className="relative z-10 container mx-auto px-4 py-4">
        <TopBar leftIcon={backIcon} />
        <h1 className="text-3xl font-bold mt-4 text-white text-center">
          Add New Challenge
        </h1>
      </div>

      <main className="relative z-10 container mx-auto px-4 mt-12">
        <form onSubmit={handleSubmit} className="space-y-4">
          <InputField
            id="title"
            type="text"
            placeholder="Challenge Title"
            value={formData.title}
            onChange={handleInputChange}
            name="title"
            required
          />
          <InputField
            id="description"
            type="text"
            placeholder="Challenge Description"
            value={formData.description}
            onChange={handleInputChange}
            name="description"
            required
          />
          <div className="relative">
            <select
              id="type"
              name="type"
              value={formData.type}
              onChange={handleInputChange}
              required
              className={`w-full p-2.5 rounded-lg ${
                darkMode
                  ? "bg-gray-700 text-white border-gray-600 focus:ring-blue-500 focus:border-blue-500"
                  : "bg-gray-50 text-gray-900 border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              } text-sm`}
            >
              <option value="numberOfItems">Number of Items</option>
            </select>
          </div>
          <DateInputField
            id="startDate"
            name="startDate"
            value={formData.startDate}
            onChange={handleInputChange}
            placeholder="Select start date"
            required
          />
          <DateInputField
            id="endDate"
            name="endDate"
            value={formData.endDate}
            onChange={handleInputChange}
            placeholder="Select end date"
            required
          />
          <InputField
            id="category"
            type="text"
            placeholder="Challenge Category"
            value={formData.category}
            onChange={handleInputChange}
            name="category"
            required
          />
          <div>
            <InputField
              id="rewardID"
              type="text"
              placeholder="Select Reward"
              value={formData.rewardID}
              onChange={handleInputChange}
              name="rewardID"
              required
              readOnly
              onClick={() => setShowRewardModal(true)}
            />
          </div>
          <InputField
            id="cost"
            type="number"
            placeholder="Challenge Cost"
            value={formData.cost}
            onChange={handleInputChange}
            name="cost"
            required
          />
          <InputField
            id="goalAmount"
            type="number"
            placeholder="Goal Amount"
            value={formData.goalAmount}
            onChange={handleInputChange}
            name="goalAmount"
            required
          />
        </form>
        <div className="mt-8">
          <Button
            label="Add Challenge"
            onClick={handleSubmit}
            variant="primary"
            fullWidth
            disabled={isLoading}
            loading={isLoading}
          >
            <Icon path={mdiTrophy} size={1} className="mr-2" />
          </Button>
        </div>
      </main>

      {showRewardModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            className={`${
              darkMode ? "bg-gray-800" : "bg-white"
            } rounded-lg p-6 w-full max-w-md`}
          >
            <div className="flex justify-between items-center mb-4">
              <h2
                className={`text-xl font-bold ${
                  darkMode ? "text-gray-200" : "text-gray-800"
                }`}
              >
                Select Reward
              </h2>
              <button onClick={() => setShowRewardModal(false)}>
                <Icon
                  path={mdiClose}
                  size={1}
                  className={darkMode ? "text-gray-400" : "text-gray-600"}
                />
              </button>
            </div>
            {rewards.length > 0 ? (
              <div className="space-y-2 max-h-60 overflow-y-auto">
                {rewards.map((reward) => (
                  <button
                    key={reward.rewardID}
                    className={`w-full p-2 text-left ${
                      darkMode
                        ? "hover:bg-gray-700 text-gray-200"
                        : "hover:bg-gray-100 text-gray-800"
                    } rounded`}
                    onClick={() => {
                      updateFormData({
                        rewardID: Number(reward.rewardID),
                      });
                      setShowRewardModal(false);
                    }}
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="font-semibold">
                          {reward.rewardText || "Unnamed Reward"}
                        </p>
                        <p className="text-sm text-gray-500">
                          Type: {reward.rewardType}
                        </p>
                      </div>
                      <p className="text-sm font-medium">
                        Cost: {reward.rewardCost}
                      </p>
                    </div>
                    {reward.minimumLevel && (
                      <p className="text-xs text-gray-500 mt-1">
                        Minimum Level: {reward.minimumLevel}
                      </p>
                    )}
                    {reward.rewardQuantity && (
                      <p className="text-xs text-gray-500">
                        Quantity: {reward.rewardQuantity}
                      </p>
                    )}
                  </button>
                ))}
              </div>
            ) : (
              <p
                className={`text-center ${
                  darkMode ? "text-gray-400" : "text-gray-600"
                }`}
              >
                No rewards available
              </p>
            )}
            <div className="mt-4">
              <Button
                label="Add New Reward"
                onClick={() =>
                  navigate("/admin/add-reward", {
                    state: { fromChallenge: true },
                  })
                }
                variant="primary"
                fullWidth
              >
                <Icon path={mdiPlus} size={1} className="mr-2" />
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminAddChallengePage;
