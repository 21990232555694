import React from "react";
import { useNavigate } from "react-router-dom";
import { useRegistration } from "../contexts/RegistrationContext";
import Button from "../components/Button";

function AccountNumberPage() {
  const { accountNumber } = useRegistration();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!accountNumber) {
      navigate("/");
    }
  }, [accountNumber, navigate]);

  if (!accountNumber) {
    return null; // or a loading spinner
  }

  return (
    <div className="flex flex-col justify-center items-center min-h-screen font-righteous bg-gray-100">
      <div className="w-[90%] max-w-md px-8 py-6 text-center bg-white rounded-[1.3em] shadow-[0_10px_25px_rgba(0,0,0,0.3)]">
        <h1 className="text-3xl font-bold text-gray-800">
          Registration Successful!
        </h1>
        <p className="mt-4 text-xl text-gray-700">Your account number is:</p>
        <p className="mt-2 text-2xl font-mono text-gray-900">{accountNumber}</p>
        <p className="mt-4 text-lg text-red-600">
          Please note this number as you won't be able to access it again.
        </p>
      </div>
      <div className="mt-6">
        <Button
          label="Next"
          onClick={() => navigate("/")}
          variant="primary"
          className="w-full"
          fullWidth
          loading={false}
        />
      </div>
    </div>
  );
}

export default AccountNumberPage;
