import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiMenu, mdiLeaf, mdiTrophy, mdiPodium } from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";
import Navbar from "../components/Navbar";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";

function GreenScorePage() {
  const { token, userData, darkMode } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (token === null) {
      navigate("/");
    }
  }, [token, navigate]);

  const menuIcon = (
    <Icon
      path={mdiMenu}
      size={1.5}
      className="text-white cursor-pointer"
      aria-label="Menu"
    />
  );

  const scoreBreakdown = useMemo(() => {
    if (!userData.transactionHistory) return [];

    const relevantTransactions = userData.transactionHistory.filter(
      (transaction) =>
        transaction.fromAccount !== 1 && transaction.amount !== 20000
    );

    const greenCompaniesScore = relevantTransactions.reduce(
      (sum, transaction) =>
        transaction.totalScore >= 0.7 ? sum + transaction.amount : sum,
      0
    );
    const amberCompaniesScore = relevantTransactions.reduce(
      (sum, transaction) =>
        transaction.totalScore >= 0.5 && transaction.totalScore < 0.7
          ? sum + transaction.amount
          : sum,
      0
    );
    const redCompaniesScore = relevantTransactions.reduce(
      (sum, transaction) =>
        transaction.totalScore < 0.5 ? sum + transaction.amount : sum,
      0
    );

    const totalSpent =
      greenCompaniesScore + amberCompaniesScore + redCompaniesScore;

    return [
      {
        name: "Green Companies",
        value: greenCompaniesScore,
        color: "#27a320",
        percentage: ((greenCompaniesScore / totalSpent) * 100).toFixed(1),
      },
      {
        name: "Amber Companies",
        value: amberCompaniesScore,
        color: "orange",
        percentage: ((amberCompaniesScore / totalSpent) * 100).toFixed(1),
      },
      {
        name: "Red Companies",
        value: redCompaniesScore,
        color: "red",
        percentage: ((redCompaniesScore / totalSpent) * 100).toFixed(1),
      },
    ];
  }, [userData.transactionHistory]);

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius * 1.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const item = scoreBreakdown[index];

    if (parseFloat(item.percentage) > 0) {
      return (
        <text
          x={x}
          y={y}
          fill={item.color}
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {`${item.percentage}%`}
        </text>
      );
    }
    return null;
  };

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900" : "bg-gray-100"
      } font-righteous pb-24 relative`}
    >
      <div className="absolute top-0 left-0 right-0 h-1/4 bg-[#319D32] rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)] z-0"></div>
      <div className="relative z-10 container mx-auto px-4 py-4">
        <TopBar leftIcon={[menuIcon]} />
      </div>

      <div className="relative z-10 container mx-auto px-4 mt-4">
        <div
          className={`${
            darkMode ? "bg-gray-800" : "bg-white"
          } rounded-[30px] shadow-md p-6`}
        >
          <div className="flex items-center justify-center mb-6">
            <div className="flex items-center">
              <Icon path={mdiLeaf} size={2} className="text-green-500" />
              <div>
                <p className="text-3xl font-bold text-green-600">
                  {userData.greenScore}
                </p>
              </div>
            </div>
          </div>

          <h2
            className={`text-xl font-bold ${
              darkMode ? "text-gray-200" : "text-gray-800"
            }`}
          >
            Score Breakdown
          </h2>
          <div
            className="flex flex-col items-center mt--5"
            style={{ height: "250px" }}
          >
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={scoreBreakdown.filter(entry => parseFloat(entry.percentage) > 0)}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  labelLine={{
                    stroke: (entry) => entry.color,
                    strokeWidth: 1,
                  }}
                  label={renderCustomizedLabel}
                >
                  {scoreBreakdown.filter(entry => parseFloat(entry.percentage) > 0).map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="space-y-4">
            {scoreBreakdown.map((item, index) => (
              <div key={index} className="flex flex-col">
                <div className="flex items-center justify-between mb-1">
                  <p
                    className={`text-lg ${
                      darkMode ? "text-gray-300" : "text-gray-800"
                    }`}
                  >
                    {item.name}
                  </p>
                  <div className="flex items-center">
                    <p
                      className={`text-lg font-bold ${
                        darkMode ? "text-gray-200" : "text-gray-800"
                      } mr-2`}
                    >
                      £{(item.value / 100).toFixed(2)}
                    </p>
                    <div
                      className="w-4 h-4 rounded-full"
                      style={{ backgroundColor: item.color }}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-8 space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                onClick={() => navigate("/challenges")}
                className={`${
                  darkMode ? "bg-green-900" : "bg-green-50"
                } rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer`}
              >
                <div className="flex items-center">
                  <div className="bg-green-500 rounded-full p-2 mr-4">
                    <Icon path={mdiTrophy} size={1.5} className="text-white" />
                  </div>
                  <div>
                    <h3
                      className={`text-lg font-semibold ${
                        darkMode ? "text-green-300" : "text-green-800"
                      }`}
                    >
                      Challenges
                    </h3>
                    <p
                      className={`text-sm ${
                        darkMode ? "text-green-400" : "text-green-600"
                      }`}
                    >
                      Take on eco-friendly tasks
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() => navigate("/leaderboard")}
                className={`${
                  darkMode ? "bg-blue-900" : "bg-blue-50"
                } rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer`}
              >
                <div className="flex items-center">
                  <div className="bg-blue-500 rounded-full p-2 mr-4">
                    <Icon path={mdiPodium} size={1.5} className="text-white" />
                  </div>
                  <div>
                    <h3
                      className={`text-lg font-semibold ${
                        darkMode ? "text-blue-300" : "text-blue-800"
                      }`}
                    >
                      Leaderboard
                    </h3>
                    <p
                      className={`text-sm ${
                        darkMode ? "text-blue-400" : "text-blue-600"
                      }`}
                    >
                      See top green performers
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navbar />
    </div>
  );
}

export default GreenScorePage;