import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import Icon from "@mdi/react";
import {
  mdiArrowLeft,
  mdiPlus,
  mdiPencil,
  mdiDelete,
  mdiGift,
  mdiTrophy,
  mdiLeaf,
  mdiAccountSupervisor,
  mdiCurrencyGbp,
} from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";
import Button from "../components/Button";
import ConfirmModal from "../components/ConfirmModal";
import SkeletonCard from "../components/SkeletonCard";

function RewardManagement() {
  const { darkMode, token } = useLogin();
  const navigate = useNavigate();
  const [rewards, setRewards] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [rewardToDelete, setRewardToDelete] = useState(null);
  const [isAddingReward, setIsAddingReward] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchRewards = async () => {
      setIsLoading(true);
      try {
        const response = await fetch("https://api.qmb.org.uk/getRewards", {
          headers: {
            Authorization: token,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setRewards(data);
        } else {
          console.error("Failed to fetch rewards");
        }
      } catch (error) {
        console.error("Error fetching rewards:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRewards();
  }, [token]);

  const backIcon = (
    <Icon
      path={mdiArrowLeft}
      size={1.5}
      className="text-white cursor-pointer"
      onClick={() => navigate("/admin-dashboard")}
    />
  );

  const openDeleteModal = (rewardID, event) => {
    event.stopPropagation();
    setRewardToDelete(rewardID);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setRewardToDelete(null);
  };

  const confirmDelete = async () => {
    if (rewardToDelete) {
      setIsDeleting(true);
      try {
        const response = await fetch(`https://api.qmb.org.uk/deleteReward`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({ rewardID: rewardToDelete }),
        });
        if (response.ok) {
          setRewards(
            rewards.filter((reward) => reward.rewardID !== rewardToDelete)
          );
        } else {
          console.error("Failed to delete reward");
        }
      } catch (error) {
        console.error("Error deleting reward:", error);
      } finally {
        setIsDeleting(false);
        closeDeleteModal();
      }
    }
  };

  const handleAddReward = async () => {
    setIsAddingReward(true);
    try {
      // Navigate to the add reward page
      navigate("/admin/add-reward");
    } catch (error) {
      console.error("Error navigating to add reward page:", error);
    } finally {
      setIsAddingReward(false);
    }
  };

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900" : "bg-gray-100"
      } font-righteous pb-24 relative`}
    >
      <div className="absolute top-0 left-0 right-0 h-[20vh] bg-[#319D32] rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)] z-0"></div>
      <div className="relative z-10 container mx-auto px-4 py-4">
        <TopBar leftIcon={backIcon} />
        <h1 className="text-3xl font-bold mt-4 text-white text-center">
          Manage Rewards
        </h1>
      </div>

      <main className="relative z-10 container mx-auto px-4 mt-5">
        <Button
          onClick={() => navigate("/admin/add-reward")}
          variant="secondary"
          className="mb-8 mt-8"
          label="Add New Reward"
          fullWidth
        />

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {isLoading
            ? Array(6).fill().map((_, index) => (
                <SkeletonCard key={index} darkMode={darkMode} />
              ))
            : rewards.map((reward) => (
                <div
                  key={reward.rewardID}
                  className={`${
                    darkMode ? "bg-gray-800" : "bg-white"
                  } rounded-lg shadow-lg overflow-hidden transition-transform duration-300 hover:scale-105 relative`}
                >
                  <div
                    className={`p-4 ${getRewardTypeColor(
                      reward.rewardType
                    )} flex justify-between items-center`}
                  >
                    <Icon
                      path={getRewardTypeIcon(reward.rewardType)}
                      size={1.5}
                      color="white"
                    />

                    <button
                      onClick={(event) => openDeleteModal(reward.rewardID, event)}
                      className="absolute top-2 right-2 text-white hover:text-red-500 transition-colors duration-200"
                      title="Delete Reward"
                    >
                      <Icon path={mdiDelete} size={1} color="#cc342e" />
                    </button>
                  </div>
                  <div className="p-4">
                    <h3
                      className={`text-xl font-bold mb-2 ${
                        darkMode ? "text-white" : "text-gray-800"
                      }`}
                    >
                      {reward.rewardTitle || reward.rewardText || "Unnamed Reward"}
                    </h3>
                    <div className="space-y-2">
                      <RewardDetail
                        icon={mdiGift}
                        label="Type"
                        value={reward.rewardType}
                      />
                      <RewardDetail
                        icon={mdiLeaf}
                        label="Cost"
                        value={reward.rewardCost}
                      />
                      {reward.minimumLevel !== null && (
                        <RewardDetail
                          icon={mdiTrophy}
                          label="Minimum Level"
                          value={reward.minimumLevel}
                        />
                      )}
                      {reward.rewardQuantity !== null && (
                        <RewardDetail
                          icon={mdiAccountSupervisor}
                          label="Quantity"
                          value={reward.rewardQuantity}
                        />
                      )}
                    </div>
                    {reward.rewardText &&
                      reward.rewardText !== reward.rewardTitle && (
                        <p
                          className={`mt-3 text-sm ${
                            darkMode ? "text-gray-400" : "text-gray-600"
                          }`}
                        >
                          {reward.rewardText}
                        </p>
                      )}
                  </div>
                </div>
              ))}
        </div>
      </main>
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
        message="Are you sure you want to delete this reward? This action cannot be undone."
        isLoading={isDeleting}
      />
    </div>
  );
}

// Helper components and functions
function RewardDetail({ icon, label, value }) {
  const { darkMode } = useLogin();
  return (
    <div className="flex items-center">
      <Icon
        path={icon}
        size={0.8}
        className={darkMode ? "text-gray-400" : "text-gray-600"}
      />
      <span
        className={`ml-2 text-sm ${
          darkMode ? "text-gray-400" : "text-gray-600"
        }`}
      >
        {label}: <span className="font-semibold">{value}</span>
      </span>
    </div>
  );
}

function getRewardTypeColor(type) {
  switch (type.toLowerCase()) {
    case "physical":
      return "bg-blue-500";
    case "digital":
      return "bg-purple-500";
    case "experience":
      return "bg-green-500";
    default:
      return "bg-gray-500";
  }
}

function getRewardTypeIcon(type) {
  switch (type.toLowerCase()) {
    case "physical":
      return mdiGift;
    case "digital":
      return mdiCurrencyGbp;
    case "experience":
      return mdiTrophy;
    default:
      return mdiGift;
  }
}

export default RewardManagement;