import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import Icon from "@mdi/react";
import {
  mdiArrowLeft,
  mdiMoonWaxingCrescent,
  mdiEyeOff,
  mdiLoading,
  mdiLogout,
} from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";
import SettingsItem from "../components/SettingsItem";
import Button from "../components/Button";

function AccountSettingsPage() {
  const { token, darkMode, toggleDarkMode, userData, updateUserData } =
    useLogin();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [localDarkMode, setLocalDarkMode] = useState(darkMode);
  const [localGreenScorePrivate, setLocalGreenScorePrivate] = useState(
    userData?.private || false
  );
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (token === null) {
      navigate("/");
    }
    // Load user preferences here
    if (userData) {
      setLocalGreenScorePrivate(userData.private || false);
    }
  }, [token, navigate, userData]);

  useEffect(() => {
    // Check if any setting has changed
    const isDarkModeChanged = localDarkMode !== darkMode;
    const isGreenScorePrivacyChanged =
      localGreenScorePrivate !== userData?.private;
    setHasChanges(isDarkModeChanged || isGreenScorePrivacyChanged);
  }, [localDarkMode, localGreenScorePrivate, darkMode, userData]);

  const backIcon = (
    <Icon
      path={mdiArrowLeft}
      size={1.5}
      className="text-white cursor-pointer"
      onClick={() => navigate(-1)}
    />
  );

  const handleLogout = () => {
    setIsLoading(true);
    navigate("/");
  };

  const handleDarkModeToggle = () => {
    setLocalDarkMode(!localDarkMode);
  };

  const handleGreenScorePrivateToggle = () => {
    setLocalGreenScorePrivate(!localGreenScorePrivate);
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    try {
      if (localDarkMode !== darkMode) {
        toggleDarkMode(localDarkMode);
      }

      if (localGreenScorePrivate !== userData?.private) {
        const apiCall = await fetch("https://api.qmb.org.uk/updatePrivacy", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            private: localGreenScorePrivate,
          }),
        });

        if (!apiCall.ok) {
          throw new Error(
            `Failed to update privacy settings: ${apiCall.status}`
          );
        }

        const responseText = await apiCall.text();
        console.log("Privacy settings updated, response:", responseText);

        if (responseText) {
          try {
            const responseData = JSON.parse(responseText);
            console.log("Parsed response data:", responseData);
          } catch (parseError) {
            console.warn("Could not parse response as JSON:", parseError);
          }
        }

        await updateUserData(token);
      }

      setHasChanges(false);
    } catch (error) {
      console.error("Error updating settings:", error);
      // Handle the error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900" : "bg-gray-100"
      } font-righteous pb-24 relative`}
    >
      <div className="absolute top-0 left-0 right-0 h-1/4 bg-[#319D32] rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)] z-0"></div>
      <div className="relative z-10 container mx-auto px-4 py-4">
        <TopBar leftIcon={backIcon} />
        <h1 className="text-3xl font-bold mt-4 text-white text-center">
          Account Settings
        </h1>
      </div>

      <div className="relative z-10 container mx-auto px-4 mt-8">
        <div
          className={`${
            darkMode ? "bg-gray-800" : "bg-white"
          } rounded-[30px] shadow-md p-6`}
        >
          <div className="space-y-6">
            <SettingsItem
              icon={mdiMoonWaxingCrescent}
              label="Dark Mode"
              toggled={localDarkMode}
              onClick={handleDarkModeToggle}
              darkMode={darkMode}
            />
            <SettingsItem
              icon={mdiEyeOff}
              label="Green Score Private"
              toggled={localGreenScorePrivate}
              onClick={handleGreenScorePrivateToggle}
              darkMode={darkMode}
            />
            <Button
              label="Logout"
              onClick={handleLogout}
              variant="primary"
              className="w-3/4 max-w-md"
              fullWidth={true}
            />
          </div>
        </div>
      </div>

      {hasChanges && (
        <div className="fixed bottom-24 left-0 right-0 flex justify-center">
          <Button
            label={isLoading ? "Saving..." : "Save Changes"}
            onClick={handleSaveChanges}
            variant="primary"
            className="w-3/4 max-w-md"
            disabled={isLoading}
            loading={isLoading}
          />
        </div>
      )}
    </div>
  );
}

export default AccountSettingsPage;
