import React from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import ChallengeCard from "../components/ChallengeCard";
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiMenu } from "@mdi/js";
import Navbar from "../components/Navbar";
import { useLogin } from "../contexts/LoginContext";

function ChallengesPage() {
  const { userData, darkMode } = useLogin();
  const navigate = useNavigate();

  const backIcon = (
    <Icon
      path={mdiArrowLeft}
      size={1.5}
      className="text-white cursor-pointer"
      aria-label="Back"
      onClick={() => navigate(-1)}
    />
  );

  const menuIcon = (
    <Icon
      path={mdiMenu}
      size={1.5}
      className="text-white cursor-pointer"
      aria-label="Open menu"
    />
  );

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900" : "bg-gray-100"
      } font-righteous pb-24 relative`}
    >
      <div
        className="absolute top-0 left-0 right-0 h-1/4 bg-[#319D32] rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)] z-0"
        aria-hidden="true"
      ></div>
      <div className="relative z-10 container mx-auto px-4 py-4">
        <TopBar leftIcon={[backIcon]} rightIcons={[menuIcon]} />
        <h1 className="text-3xl font-bold mt-4 text-white text-center">
          Challenges
        </h1>
      </div>

      <main className="relative z-10 container mx-auto px-4 mt-8">
        {userData.challenges.length > 0 ? (
          <ul className="space-y-4">
            {userData.challenges.map((challenge) => (
              <li key={challenge.challengeID}>
                <ChallengeCard
                  challenge={challenge}
                  onClick={() =>
                    navigate(`/challenge-details/${challenge.challengeID}`)
                  }
                  darkMode={darkMode}
                />
              </li>
            ))}
          </ul>
        ) : (
          <p
            className={`text-center ${
              darkMode ? "text-gray-400" : "text-gray-600"
            }`}
          >
            No challenges available at the moment.
          </p>
        )}
      </main>
    </div>
  );
}

export default ChallengesPage;
