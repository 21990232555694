import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import Icon from "@mdi/react";
import {
  mdiArrowLeft,
  mdiMagnify,
  mdiChevronLeft,
  mdiChevronRight,
  mdiStar,
  mdiStarOutline,
} from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";
import Button from "../components/Button";
import { showErrorToast } from "../components/Toast";

function LeaderboardPage() {
  const { userData, darkMode, watchedAccounts, watchAccount, unwatchAccount } = useLogin();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;
  const [activeTab, setActiveTab] = useState('chosen');

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const backIcon = (
    <Icon
      path={mdiArrowLeft}
      size={1.5}
      className="text-white cursor-pointer"
      aria-label="Go back"
      onClick={() => navigate(-1)}
    />
  );

  const getRankColor = (index) => {
    if (index === 0) return "text-yellow-400"; // Gold
    if (index === 1) return "text-gray-400"; // Silver
    if (index === 2) return "text-orange-400"; // Bronze
    return darkMode ? "text-gray-400" : "text-gray-600"; // Default color for other ranks
  };

  const businessNames =
    userData.payees?.map((payee) => payee.name.toLowerCase()) || [];

  const getChosenLeaderboard = useMemo(() => {
    return userData.chosenLeaderboard
      ? userData.chosenLeaderboard
          .map((user) => ({
            ...user,
            greenScore: user.greenScore === null ? 0 : user.greenScore,
          }))
          .sort((a, b) => b.greenScore - a.greenScore)
      : [];
  }, [userData.chosenLeaderboard]);

  const getGlobalLeaderboard = useMemo(() => {
    return userData.globalLeaderboard
      ? userData.globalLeaderboard
          .filter(
            (user) =>
              !user.private && !businessNames.includes(user.name.toLowerCase())
          )
          .map((user) => ({
            ...user,
            greenScore: user.greenScore === null ? 0 : user.greenScore,
          }))
          .sort((a, b) => b.greenScore - a.greenScore)
      : [];
  }, [userData.globalLeaderboard, businessNames]);

  // Filter leaderboard based on search term
  const filteredLeaderboard = useMemo(() => {
    return activeTab === 'chosen' ? getChosenLeaderboard : getGlobalLeaderboard;
  }, [getChosenLeaderboard, getGlobalLeaderboard, activeTab]);

  // Paginate the filtered leaderboard
  const paginatedLeaderboard = useMemo(() => {
    const startIndex = (currentPage - 1) * usersPerPage;
    return filteredLeaderboard.slice(startIndex, startIndex + usersPerPage);
  }, [filteredLeaderboard, currentPage]);

  const totalPages = Math.ceil(filteredLeaderboard.length / usersPerPage);

  const isGreenScorePrivate = userData.private;

  const handleToggleWatch = async (accountToWatch) => {
    console.log('Attempting to toggle watch for account:', accountToWatch);
    try {
      if (!accountToWatch) {
        throw new Error('Invalid account to watch');
      }
      if (watchedAccounts.has(accountToWatch)) {
        await unwatchAccount(accountToWatch);
      } else {
        await watchAccount(accountToWatch);
      }
    } catch (error) {
      console.error('Error toggling watch status:', error);
      if (error instanceof SyntaxError) {
        showErrorToast('Server returned an invalid response. Please try again later.');
      } else {
        showErrorToast(error.message || 'Failed to update watch status');
      }
    }
  };

  const LeaderboardList = ({ leaderboard, isGlobal, onToggleWatch }) => {
    const { watchedAccounts, userData } = useLogin();

    console.log('Watched Accounts:', Array.from(watchedAccounts));
    console.log('Current Leaderboard:', leaderboard);

    return (
      <ul className="space-y-4">
        {leaderboard.map((user) => {
          const isWatched = watchedAccounts.has(user.accountNumber);
          console.log(`User ${user.name} (${user.accountNumber}) is watched: ${isWatched}`);

          return (
            <li
              key={user.accountNumber}
              className={`flex items-center justify-between ${
                darkMode ? "bg-gray-700" : "bg-gray-50"
              } p-1 rounded-lg shadow-[0_1px_1px_rgba(0,0,0,0.3)]`}
            >
              <div className="flex items-center flex-grow">
                <span
                  className={`text-2xl font-bold mx-2 ${getRankColor(leaderboard.indexOf(user))}`}
                >
                  {leaderboard.indexOf(user) + 1}
                </span>
                <div>
                  <p
                    className={`text-lg font-bold ${
                      darkMode ? "text-gray-200" : "text-gray-800"
                    }`}
                  >
                    {user.name}
                  </p>
                  {user.title && (
                    <p
                      className={`text-sm ${
                        darkMode ? "text-gray-400" : "text-gray-500"
                      }`}
                    >
                      {user.title}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center">
                <p
                  className={`text-xl font-bold ${
                    user.greenScore >= 0
                      ? "text-green-600"
                      : "text-red-600"
                  } mr-2`}
                >
                  {user.greenScore}
                </p>
                {user.badge && (
                  <span
                    className={`${
                      darkMode
                        ? "bg-blue-900 text-blue-200"
                        : "bg-blue-100 text-blue-800"
                    } text-xs font-medium px-2.5 py-0.5 rounded mr-2`}
                  >
                    {user.badge}
                  </span>
                )}
                {isGlobal && user.accountNumber !== userData.accountNumber && (
                  <button
                    onClick={() => onToggleWatch(user.accountNumber)}
                    className="p-1 rounded-full hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-200"
                    aria-label={isWatched ? "Remove from watched" : "Add to watched"}
                  >
                    <Icon
                      path={isWatched ? mdiStar : mdiStarOutline}
                      size={1}
                      className={isWatched ? "text-yellow-400" : "text-gray-400"}
                    />
                  </button>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900" : "bg-gray-100"
      } font-righteous pb-24 relative`}
    >
      <div className="absolute top-0 left-0 right-0 h-1/4 bg-[#319D32] rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)] z-0"></div>
      <div className="relative z-10 container mx-auto px-4 py-4">
        <TopBar leftIcon={[backIcon]} />
        <h1 className="text-3xl font-bold mt-4 text-white text-center">
          Leaderboard
        </h1>
      </div>

      <div className="relative z-10 container mx-auto px-4 mt-8">
        <div
          className={`${
            darkMode ? "bg-gray-800" : "bg-white"
          } rounded-[30px] shadow-md p-6`}
        >
          {isGreenScorePrivate ? (
            <div className="text-center">
              <p
                className={`${
                  darkMode ? "text-gray-300" : "text-gray-600"
                } mb-4`}
              >
                To see the leaderboard, your Green Score must not be private.
              </p>
              <Button
                label="Go to Settings"
                onClick={() => navigate("/account-settings")}
                variant="primary"
                className="mt-4"
              />
            </div>
          ) : (
            <>
              <div className="mb-4">
                <div className="flex border-b border-gray-200">
                  <button
                    className={`py-2 px-4 ${
                      activeTab === 'chosen'
                        ? 'border-b-2 border-green-500 font-medium text-green-600'
                        : 'text-gray-500 hover:text-gray-700'
                    }`}
                    onClick={() => setActiveTab('chosen')}
                  >
                    My Leaderboard
                  </button>
                  <button
                    className={`py-2 px-4 ${
                      activeTab === 'global'
                        ? 'border-b-2 border-green-500 font-medium text-green-600'
                        : 'text-gray-500 hover:text-gray-700'
                    }`}
                    onClick={() => setActiveTab('global')}
                  >
                    Global Leaderboard
                  </button>
                </div>
              </div>
              <div className="mb-4">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      setCurrentPage(1);
                    }}
                    className={`w-full p-2 pl-10 rounded-lg ${
                      darkMode
                        ? "bg-gray-700 text-white"
                        : "bg-gray-100 text-gray-900"
                    } focus:outline-none focus:ring-2 focus:ring-green-500`}
                  />
                  <Icon
                    path={mdiMagnify}
                    size={1}
                    className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  />
                </div>
              </div>
              <div
                className={`flex justify-between mb-4 px-4 font-bold ${
                  darkMode ? "text-gray-300" : "text-gray-600"
                }`}
              >
                <span>Name</span>
                <span>Green Score</span>
              </div>
              <LeaderboardList 
                leaderboard={paginatedLeaderboard}
                isGlobal={activeTab === 'global'}
                onToggleWatch={handleToggleWatch}
              />
              {filteredLeaderboard.length === 0 && (
                <p
                  className={`text-center ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  } mt-4`}
                >
                  No users found matching your search.
                </p>
              )}
              {filteredLeaderboard.length > 0 && (
                <div className="mt-6 flex justify-between items-center">
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    disabled={currentPage === 1}
                    className={`p-2 rounded-full ${
                      darkMode
                        ? "bg-gray-700 text-gray-300"
                        : "bg-gray-200 text-gray-600"
                    } ${
                      currentPage === 1
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-green-500 hover:text-white"
                    }`}
                  >
                    <Icon path={mdiChevronLeft} size={1} />
                  </button>
                  <span
                    className={`${
                      darkMode ? "text-gray-300" : "text-gray-600"
                    }`}
                  >
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={currentPage === totalPages}
                    className={`p-2 rounded-full ${
                      darkMode
                        ? "bg-gray-700 text-gray-300"
                        : "bg-gray-200 text-gray-600"
                    } ${
                      currentPage === totalPages
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-green-500 hover:text-white"
                    }`}
                  >
                    <Icon path={mdiChevronRight} size={1} />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default LeaderboardPage;