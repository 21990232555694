import React from "react";

function InputField({
  type = "text",
  placeholder,
  value,
  onChange,
  name,
  icon,
  onIconClick,
  darkMode,
  ...props
}) {
  return (
    <div className="relative">
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        className={`w-full px-4 py-3 mt-2 ${
          darkMode ? "text-white bg-gray-800" : "text-gray-700 bg-white"
        } rounded-[1.3em] shadow-[0_2px_2px_rgba(0,0,0,0.3)] focus:outline-none focus:ring-0 font-righteous pr-10 placeholder-${
          darkMode ? "gray-400" : "gray-500"
        }`}
        {...props}
      />
      {icon && (
        <button
          type="button"
          onClick={onIconClick}
          className="absolute inset-y-0 right-0 flex items-center pr-3 mt-2"
          aria-label={`${name} action`}
        >
          {icon}
        </button>
      )}
    </div>
  );
}

export default InputField;
