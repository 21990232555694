import React from "react";
import Button from "./Button";
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

function ConfirmModal({ isOpen, onClose, onConfirm, message, isLoading }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-sm w-full">
        <h2 className="text-xl font-bold mb-4 dark:text-white">
          Confirm Action
        </h2>
        <p className="mb-6 dark:text-gray-300">{message}</p>
        <div className="flex justify-end space-x-2">
          <Button
            onClick={onClose}
            variant="secondary"
            label="Cancel"
            disabled={isLoading}
          ></Button>
          <Button
            onClick={onConfirm}
            variant="danger"
            label="Confirm"
            disabled={isLoading}
            loading={isLoading}
          ></Button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;
