import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiMagnify, mdiAccountCircle, mdiClose, mdiFilter, mdiChevronUp } from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";

function SearchPayeesPage() {
  const navigate = useNavigate();
  const { userData, darkMode } = useLogin();
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState("all");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);

  const categories = useMemo(() => {
    const allCategories = userData.payees?.map(payee => payee.category) || [];
    return [...new Set(allCategories)].sort();
  }, [userData.payees]);

  const filteredPayees = useMemo(() => {
    let payees = userData.payees || [];

    if (searchQuery) {
      const lowerSearch = searchQuery.toLowerCase();
      payees = payees.filter(
        (payee) =>
          payee.name.toLowerCase().includes(lowerSearch) ||
          payee.accountNumber.toString().includes(searchQuery)
      );
    }

    if (activeTab !== "all") {
      payees = payees.filter((payee) => {
        if (activeTab === "green") return payee.totalScore >= 0.7;
        if (activeTab === "amber")
          return payee.totalScore >= 0.5 && payee.totalScore < 0.7;
        if (activeTab === "red") return payee.totalScore < 0.5;
        return true;
      });
    }

    if (selectedCategories.length > 0) {
      payees = payees.filter((payee) => selectedCategories.includes(payee.category));
    }

    if (activeTab === "all") {
      payees.sort((a, b) => b.totalScore - a.totalScore);
    }

    return payees;
  }, [searchQuery, userData.payees, activeTab, selectedCategories]);

  const backIcon = (
    <Icon
      path={mdiArrowLeft}
      size={1.5}
      className="text-white cursor-pointer"
      onClick={() => navigate(-1)}
      aria-label="Go back"
    />
  );

  const tabs = [
    { id: "all", label: "All" },
    { id: "green", label: "Green" },
    { id: "amber", label: "Amber" },
    { id: "red", label: "Red" },
  ];

  const getRagColor = (score) => {
    if (score >= 0.7) return "bg-green-500";
    if (score >= 0.5) return "bg-yellow-500";
    return "bg-red-500";
  };

  const toggleCategory = (category) => {
    setSelectedCategories(prev =>
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setShowScrollTopButton(true);
      } else {
        setShowScrollTopButton(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900' : 'bg-gray-100'} font-righteous pb-24`}>
      <div className="bg-[#319D32] pt-4 pb-16 rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)]">
        <div className="container mx-auto px-4">
          <TopBar leftIcon={backIcon} />
          <h1 className="text-3xl font-bold mb-3 text-white text-center mt-4">
            Search Payees
          </h1>
        </div>
      </div>

      <div className="container mx-auto px-4 -mt-12">
        <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-[30px] shadow-[0_4px_2px_rgba(0,0,0,0.1)] p-6`}>
          <div className="flex items-center mb-4">
            <div className="relative flex-grow mr-2">
              <input
                type="text"
                placeholder="Search Payee"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={`w-full pl-10 pr-3 py-2 border ${darkMode ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'} rounded-full focus:outline-none focus:ring-2 focus:ring-[#319D32] focus:border-transparent`}
              />
              <Icon
                path={mdiMagnify}
                size={1}
                className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}
              />
            </div>
            <button
              onClick={() => setShowFilterModal(true)}
              className="bg-[#319D32] text-white p-2 rounded-full hover:bg-[#2c8c2d] transition-colors"
              aria-label="Filter categories"
            >
              <Icon path={mdiFilter} size={1} />
            </button>
          </div>

          <div className={`flex mb-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-100'} rounded-full p-1`}>
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`flex-1 py-2 text-sm font-medium rounded-full transition-colors ${
                  activeTab === tab.id
                    ? `${darkMode ? 'bg-gray-800' : 'bg-white'} text-[#319D32] shadow`
                    : `${darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-500 hover:text-gray-700'}`
                }`}
              >
                {tab.label}
              </button>
            ))}
          </div>

          <ul className="mt-6 space-y-2">
            {filteredPayees.map((payee) => (
              <li key={payee.accountNumber}>
                <button
                  className={`w-full flex items-center p-3 ${darkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-50 hover:bg-gray-100'} rounded-lg transition-colors`}
                  onClick={() => navigate(`/payee-details/${payee.accountNumber}`)}
                >
                  <Icon
                    path={mdiAccountCircle}
                    size={1.5}
                    className={`${darkMode ? 'text-gray-400' : 'text-gray-500'} mr-3`}
                  />
                  <div className="text-left flex-grow">
                    <h2 className={`font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>{payee.name}</h2>
                    <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      {payee.accountNumber}
                    </p>
                  </div>
                  <div
                    className={`w-3 h-3 rounded-full ${getRagColor(
                      payee.totalScore
                    )}`}
                  ></div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {showFilterModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-[30px] p-6 w-full max-w-md m-4`}>
            <div className="flex justify-between items-center mb-6">
              <h2 className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-gray-800'}`}>Filter Categories</h2>
              <button onClick={() => setShowFilterModal(false)} className={`${darkMode ? 'text-gray-400 hover:text-white' : 'text-gray-500 hover:text-gray-700'}`}>
                <Icon path={mdiClose} size={1} />
              </button>
            </div>
            <div className="grid grid-cols-2 gap-3 mb-6">
              {categories.map((category) => (
                <button
                  key={category}
                  onClick={() => toggleCategory(category)}
                  className={`p-3 rounded-lg text-sm font-medium transition-colors ${
                    selectedCategories.includes(category)
                      ? "bg-[#319D32] text-white"
                      : darkMode
                        ? "bg-gray-700 text-gray-300 hover:bg-gray-600"
                        : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                  }`}
                >
                  {category}
                </button>
              ))}
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => setShowFilterModal(false)}
                className="bg-[#319D32] text-white py-2 px-6 rounded-full font-medium hover:bg-[#2c8c2d] transition-colors"
              >
                Apply Filters
              </button>
            </div>
          </div>
        </div>
      )}

      {showScrollTopButton && (
        <button
          className="fixed bottom-6 right-6 bg-[#319D32] text-white p-3 rounded-full shadow-lg hover:bg-[#2c8c2d] transition-colors"
          onClick={scrollToTop}
          aria-label="Scroll to top"
        >
          <Icon path={mdiChevronUp} size={1} />
        </button>
      )}
    </div>
  );
}

export default SearchPayeesPage;