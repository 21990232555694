import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiPlus, mdiDelete, mdiCalendarRange, mdiTarget, mdiCurrencyUsd } from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";
import Button from "../components/Button";
import ConfirmModal from "../components/ConfirmModal";
import SkeletonCard from "../components/SkeletonCard";

function ChallengeManagement() {
  const { darkMode, token } = useLogin();
  const navigate = useNavigate();
  const [challenges, setChallenges] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [challengeToDelete, setChallengeToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchChallenges = async () => {
      setIsLoading(true);
      try {
        const response = await fetch("https://api.qmb.org.uk/getChallenges", {
          headers: {
            Authorization: token,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setChallenges(Array.isArray(data) ? data : []);
        } else {
          console.error("Failed to fetch challenges");
          setChallenges([]);
        }
      } catch (error) {
        console.error("Error fetching challenges:", error);
        setChallenges([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChallenges();
  }, [token]);

  const backIcon = (
    <Icon
      path={mdiArrowLeft}
      size={1.5}
      className="text-white cursor-pointer"
      onClick={() => navigate("/admin-dashboard")}
    />
  );

  const openDeleteModal = (challengeID, event) => {
    event.stopPropagation();
    setChallengeToDelete(challengeID);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setChallengeToDelete(null);
  };

  const confirmDelete = async () => {
    if (challengeToDelete) {
      setIsDeleting(true);
      try {
        const response = await fetch(`https://api.qmb.org.uk/deleteChallenge`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({ challengeID: challengeToDelete }),
        });
        if (response.ok) {
          setChallenges(
            challenges.filter((challenge) => challenge.challengeID !== challengeToDelete)
          );
        } else {
          console.error("Failed to delete challenge");
        }
      } catch (error) {
        console.error("Error deleting challenge:", error);
      } finally {
        setIsDeleting(false);
        closeDeleteModal();
      }
    }
  };

  return (
    <div className={`min-h-screen ${darkMode ? "bg-gray-900" : "bg-gray-100"} font-righteous pb-24 relative`}>
      <div className="absolute top-0 left-0 right-0 h-[20vh] bg-[#319D32] rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)] z-0"></div>
      <div className="relative z-10 container mx-auto px-4 py-4">
        <TopBar leftIcon={backIcon} />
        <h1 className="text-3xl font-bold mt-4 text-white text-center">
          Manage Challenges
        </h1>
      </div>

      <main className="relative z-10 container mx-auto px-4 mt-5">
        <Button
          onClick={() => navigate("/admin/add-challenge")}
          variant="secondary"
          className="mb-8 mt-8"
          label="Add New Challenge"
          fullWidth
        />

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {isLoading
            ? Array(6).fill().map((_, index) => (
                <SkeletonCard key={index} darkMode={darkMode} />
              ))
            : challenges.map((challenge) => (
                <div
                  key={challenge.challengeID}
                  className={`${darkMode ? "bg-gray-800" : "bg-white"} rounded-lg p-6 shadow-lg relative`}
                >
                  <h3 className={`text-xl font-bold ${darkMode ? "text-white" : "text-gray-800"} mb-2`}>
                    {challenge.title}
                  </h3>
                  <p className={`text-sm ${darkMode ? "text-gray-400" : "text-gray-600"} mb-4`}>
                    {challenge.description || "No description available"}
                  </p>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <Icon
                        path={mdiCalendarRange}
                        size={0.8}
                        className={`${darkMode ? "text-gray-400" : "text-gray-600"} mr-2`}
                      />
                      <span className={`text-sm ${darkMode ? "text-gray-300" : "text-gray-700"}`}>
                        {new Date(challenge.startDate).toLocaleDateString()} - {new Date(challenge.endDate).toLocaleDateString()}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Icon
                        path={mdiTarget}
                        size={0.8}
                        className={`${darkMode ? "text-gray-400" : "text-gray-600"} mr-2`}
                      />
                      <span className={`text-sm ${darkMode ? "text-gray-300" : "text-gray-700"}`}>
                        Goal: {challenge.goalAmount}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Icon
                        path={mdiCurrencyUsd}
                        size={0.8}
                        className={`${darkMode ? "text-gray-400" : "text-gray-600"} mr-2`}
                      />
                      <span className={`text-sm ${darkMode ? "text-gray-300" : "text-gray-700"}`}>
                        Cost: {challenge.cost}
                      </span>
                    </div>
                  </div>
                  <button
                    onClick={(event) => openDeleteModal(challenge.challengeID, event)}
                    className="absolute top-2 right-2 text-white hover:text-red-500 transition-colors duration-200"
                    title="Delete Challenge"
                  >
                    <Icon path={mdiDelete} size={1} color="#cc342e" />
                  </button>
                </div>
              ))}
        </div>
      </main>
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
        message="Are you sure you want to delete this challenge? This action cannot be undone."
        isLoading={isDeleting}
      />
    </div>
  );
}

export default ChallengeManagement;