import React from "react";
import { useLogin } from "../contexts/LoginContext";

function DateInputField({ id, name, value, onChange, placeholder, required }) {
  const { darkMode } = useLogin();

  return (
    <div className="relative">
      <input
        type="date"
        id={id}
        name={name}
        value={value} // This should already be in YYYY-MM-DD format
        onChange={onChange}
        required={required}
        className={`w-full p-2.5 rounded-lg ${
          darkMode
            ? "bg-gray-700 text-white border-gray-600 focus:ring-blue-500 focus:border-blue-500"
            : "bg-gray-50 text-gray-900 border-gray-300 focus:ring-blue-500 focus:border-blue-500"
        } text-sm`}
      />
      <span
        className={`absolute left-2.5 top-2.5 text-gray-400 pointer-events-none ${
          value ? "hidden" : ""
        }`}
      >
        {placeholder}
      </span>
    </div>
  );
}

export default DateInputField;
