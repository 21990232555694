import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import AccountNumberPage from "./pages/AccountNumberPage";
import HomePage from "./pages/HomePage";
import BankPage from "./pages/BankPage";
import SendMoneyPage from "./pages/SendMoneyPage";
import TransactionHistoryPage from "./pages/TransactionHistoryPage";
import TransactionDetailPage from "./pages/TransactionPage";
import ChallengesPage from "./pages/ChallengesPage";
import ChallengeDetailsPage from "./pages/ChallengeDetailsPage";
import AccountPage from "./pages/AccountPage";
import TermsPage from "./pages/TermsPage";
import GreenScorePage from "./pages/GreenScorePage";
import LeaderboardPage from "./pages/LeaderboardPage";
import ShopPage from "./pages/ShopPage";
import AccountSettingsPage from "./pages/AccountSettingsPage";
import SearchPayeesPage from "./pages/SearchPayeesPage";
import PayeeDetailsPage from "./pages/PayeeDetailsPage";
import AdminDashboard from "./pages/AdminDashboardPage";
import RewardManagement from "./pages/RewardManagementPage";
import ChallengeManagement from "./pages/ChallengeManagementPage";
import UserManagement from "./pages/UserManagementPage";
import AdminAddUserPage from "./pages/AdminAddUserPage";
import AdminAddRewardPage from "./pages/AdminAddRewardPage";
import AdminAddChallengePage from "./pages/AdminAddChallengePage";

import Toast from "./components/Toast";

import { LoginProvider } from "./contexts/LoginContext";
import { RegistrationProvider } from "./contexts/RegistrationContext";
import { CreateChallengeProvider } from "./contexts/CreateChallengeContext";

import { useLogin } from "./contexts/LoginContext";

function App() {
  const { darkMode } = useLogin();

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <Router>
      <Toast />
      <RegistrationProvider>
        <LoginProvider>
          <CreateChallengeProvider>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/account-number" element={<AccountNumberPage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/bank" element={<BankPage />} />
              <Route path="/send-money" element={<SendMoneyPage />} />
              <Route
                path="/transaction-history"
                element={<TransactionHistoryPage />}
              />
              <Route
                path="/transaction-details/:id"
                element={<TransactionDetailPage />}
              />
              <Route path="/challenges" element={<ChallengesPage />} />
              <Route
                path="/challenge-details/:id"
                element={<ChallengeDetailsPage />}
              />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/terms" element={<TermsPage />} />
              <Route path="/green-score" element={<GreenScorePage />} />
              <Route path="/leaderboard" element={<LeaderboardPage />} />
              <Route path="/shop" element={<ShopPage />} />
              <Route
                path="/account-settings"
                element={<AccountSettingsPage />}
              />
              <Route path="/search-payees" element={<SearchPayeesPage />} />
              <Route
                path="/payee-details/:accountNumber"
                element={<PayeeDetailsPage />}
              />
              <Route path="/admin-dashboard" element={<AdminDashboard />} />
              <Route path="/admin/rewards" element={<RewardManagement />} />
              <Route
                path="/admin/challenges"
                element={<ChallengeManagement />}
              />
              <Route path="/admin/users" element={<UserManagement />} />
              <Route path="/admin/add-user" element={<AdminAddUserPage />} />
              <Route
                path="/admin/add-reward"
                element={<AdminAddRewardPage />}
              />
              <Route
                path="/admin/add-challenge"
                element={<AdminAddChallengePage />}
              />
            </Routes>
          </CreateChallengeProvider>
        </LoginProvider>
      </RegistrationProvider>
    </Router>
  );
}

export default App;
