import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TopBar from "../components/TopBar";
import InputField from "../components/InputField";
import Button from "../components/Button";
import Icon from "@mdi/react";
import {
  mdiArrowLeft,
  mdiMenu,
  mdiAccountCircle,
  mdiMagnify,
  mdiCheckCircle,
  mdiClose,
} from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";
import { showErrorToast } from "../components/Toast";

function SendMoneyPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { token, userData, updateUserData, darkMode } = useLogin();
  const [amount, setAmount] = useState("");
  const [payeeSearch, setPayeeSearch] = useState("");
  const [selectedPayee, setSelectedPayee] = useState(null);
  const [reference, setReference] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPayeeDropdown, setShowPayeeDropdown] = useState(false);
  const [showPayeeModal, setShowPayeeModal] = useState(false);
  const [transferSuccess, setTransferSuccess] = useState(false);

  useEffect(() => {
    if (token === null) {
      navigate("/");
    }
  }, [token, navigate]);

  useEffect(() => {
    if (transferSuccess) {
      const timer = setTimeout(() => {
        navigate("/green-score");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [transferSuccess, navigate]);

  useEffect(() => {
    const { state } = location;
    if (state && state.selectedPayee) {
      setSelectedPayee(state.selectedPayee);
      setPayeeSearch(state.selectedPayee.name);
    }
  }, []);

  const backIcon = (
    <Icon
      path={mdiArrowLeft}
      size={1.5}
      className="text-white cursor-pointer"
      onClick={() => navigate(-1)}
      aria-label="Go back"
    />
  );

  const menuIcon = (
    <Icon
      path={mdiMenu}
      size={1.5}
      className="text-white cursor-pointer"
      aria-label="Menu"
    />
  );

  const handleSendMoney = async () => {
    if (!validateForm()) return;
    setLoading(true);
    try {
      const apiCall = await fetch("https://api.qmb.org.uk/transaction", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
        method: "POST",
        body: JSON.stringify({
          amount: Math.round(parseFloat(amount) * 100),
          toAccount: selectedPayee.accountNumber,
          reference: reference,
        }),
      });

      if (!apiCall.ok) {
        throw new Error("Transaction failed!");
      }

      setTransferSuccess(true);
    } catch (error) {
      console.error("Error sending money:", error);
      showErrorToast("Failed to send money. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePayeeSelect = (payee) => {
    setSelectedPayee(payee);
    setPayeeSearch(payee.name);
    setShowPayeeDropdown(false);
    setShowPayeeModal(false);
  };

  const validateForm = () => {
    if (!selectedPayee) {
      showErrorToast("Please select a payee");
      return false;
    }
    if (!amount || isNaN(parseFloat(amount)) || parseFloat(amount) <= 0) {
      showErrorToast("Please enter a valid amount");
      return false;
    }
    if (!reference) {
      showErrorToast("Please enter a reference");
      return false;
    }
    return true;
  };

  const filteredPayees = useMemo(() => {
    return (userData.payees || []).filter(payee =>
      payee.name.toLowerCase().includes(payeeSearch.toLowerCase()) ||
      payee.accountNumber.toString().includes(payeeSearch)
    );
  }, [userData.payees, payeeSearch]);

  const sortedPayees = useMemo(() => {
    return [...(userData.payees || [])].sort((a, b) => {
      return a.accountNumber - b.accountNumber;
    });
  }, [userData.payees]);

  if (transferSuccess) {
    return (
      <div className="min-h-screen bg-[#319D32] flex flex-col items-center justify-center font-righteous">
        <Icon path={mdiCheckCircle} size={4} className="text-white mb-4" />
        <h1 className="text-3xl font-bold text-white text-center mb-4">
          Transfer Successful!
        </h1>
        <p className="text-white text-xl mb-2">
          £{amount} sent to {selectedPayee.name}
        </p>
        <p className="text-white text-lg mb-8">Reference: {reference}</p>
      </div>
    );
  }

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900" : "bg-gray-100"
      } font-righteous pb-24 flex flex-col`}
    >
      <div className="bg-[#319D32] pt-4 pb-16 rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)]">
        <div className="container mx-auto px-4">
          <TopBar leftIcon={backIcon} rightIcons={[menuIcon]} />
          <h1 className="text-3xl font-bold mb-3 text-white text-center mt-4">
            Send Money
          </h1>
        </div>
      </div>

      <div className="container mx-auto px-4 -mt-12 flex-grow">
        <div
          className={`${
            darkMode ? "bg-gray-800" : "bg-white"
          } rounded-[30px] shadow-[0_4px_2px_rgba(0,0,0,0.1)] p-6`}
        >
          <div className="mb-6 relative">
            <InputField
              type="text"
              placeholder="Search Payee"
              value={payeeSearch}
              onChange={(e) => {
                setPayeeSearch(e.target.value);
                setShowPayeeDropdown(true);
                setSelectedPayee(null);
              }}
              name="payee"
              icon={
                <Icon
                  path={mdiMagnify}
                  size={0.9}
                  color={darkMode ? "#ffffff" : "#6B7280"}
                />
              }
              onIconClick={() => setShowPayeeModal(true)}
              aria-label="Search Payee"
              darkMode={darkMode}
            />
            {showPayeeDropdown && filteredPayees.length > 0 && (
              <div
                className={`absolute z-10 w-full ${
                  darkMode ? "bg-gray-700" : "bg-white"
                } border ${
                  darkMode ? "border-gray-600" : "border-gray-300"
                } rounded-md shadow-lg mt-1 max-h-60 overflow-auto`}
              >
                {filteredPayees.map((payee) => (
                  <button
                    key={payee.id}
                    className={`flex items-center w-full px-4 py-2 text-left ${
                      darkMode ? "hover:bg-gray-600" : "hover:bg-gray-100"
                    }`}
                    onClick={() => handlePayeeSelect(payee)}
                  >
                    <Icon
                      path={mdiAccountCircle}
                      size={1}
                      className={`${
                        darkMode ? "text-gray-400" : "text-gray-500"
                      } mr-2`}
                    />
                    <div>
                      <div
                        className={`font-semibold ${
                          darkMode ? "text-white" : "text-gray-800"
                        }`}
                      >
                        {payee.name}
                      </div>
                      <div
                        className={`text-sm ${
                          darkMode ? "text-gray-400" : "text-gray-500"
                        }`}
                      >
                        {payee.accountNumber}
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>
          <div className="mb-6">
            <InputField
              type="number"
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              name="amount"
              aria-label="Amount"
              darkMode={darkMode}
            />
          </div>
          <div className="mb-6">
            <InputField
              type="text"
              placeholder="Reference"
              value={reference}
              onChange={(e) => setReference(e.target.value)}
              name="reference"
              aria-label="Reference"
              darkMode={darkMode}
            />
          </div>
          <Button
            label="Send Money"
            onClick={handleSendMoney}
            variant="primary"
            fullWidth
            loading={loading}
            aria-label="Send Money"
          />
        </div>
      </div>

      {/* Payee Modal */}
      {showPayeeModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            className={`${
              darkMode ? "bg-gray-800" : "bg-white"
            } rounded-lg w-11/12 max-w-md max-h-[80vh] flex flex-col`}
          >
            <div
              className={`p-4 border-b ${
                darkMode ? "border-gray-700" : "border-gray-200"
              } sticky top-0 ${
                darkMode ? "bg-gray-800" : "bg-white"
              } z-10 rounded-t-lg`}
            >
              <div className="flex justify-between items-center">
                <h2
                  className={`text-xl font-bold ${
                    darkMode ? "text-white" : "text-gray-800"
                  }`}
                >
                  Select a Payee
                </h2>
                <button
                  onClick={() => setShowPayeeModal(false)}
                  className={`${
                    darkMode
                      ? "text-gray-400 hover:text-gray-200"
                      : "text-gray-500 hover:text-gray-700"
                  }`}
                >
                  <Icon path={mdiClose} size={1} />
                </button>
              </div>
            </div>
            <div className="p-4 space-y-4 overflow-y-auto flex-grow">
              {sortedPayees.map((payee) => (
                <div
                  key={payee.id}
                  className={`flex items-center ${
                    darkMode
                      ? "bg-gray-700 hover:bg-gray-600"
                      : "bg-gray-100 hover:bg-gray-200"
                  } rounded-[15px] p-4 cursor-pointer`}
                  onClick={() => handlePayeeSelect(payee)}
                >
                  <Icon
                    path={mdiAccountCircle}
                    size={1.5}
                    className={`${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    } mr-4`}
                  />
                  <div>
                    <span
                      className={`${
                        darkMode ? "text-white" : "text-gray-800"
                      } font-bold`}
                    >
                      {payee.name}
                    </span>
                    <br />
                    <span
                      className={`${
                        darkMode ? "text-gray-400" : "text-gray-500"
                      } text-sm`}
                    >
                      {payee.accountNumber}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SendMoneyPage;