import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { showErrorToast } from "../components/Toast";
import loginBackground from "../assets/images/login-background.png";
import InputField from "../components/InputField";
import Button from "../components/Button";
import { useLogin } from "../contexts/LoginContext";

function LoginPage() {
  const [accountNumber, setAccountNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { setToken, setIsLoading, setIsAdmin } = useLogin();
  const navigate = useNavigate();

  const validateForm = () => {
    if (!accountNumber || !password) {
      showErrorToast("Please enter both account number and password.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    const startTime = Date.now();

    try {
      const apiCall = await fetch("https://api.qmb.org.uk/login", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          accountNumber: parseInt(accountNumber, 10),
          password: password,
        }),
      });

      if (!apiCall.ok) {
        const errorData = await apiCall.json();
        throw new Error(errorData.message || "Login failed. Please try again.");
      }

      const json = await apiCall.json();

      setToken(json["token"]);
      setIsLoading(true); // Set loading state to true before navigating
      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(0, 2000 - elapsedTime);

      setTimeout(() => {
        setLoading(false);
        if (accountNumber === "0") {
          setIsAdmin(true);
          navigate("/admin-dashboard");
        } else {
          navigate("/home");
        }
      }, remainingTime);
    } catch (error) {
      console.error(error);
      showErrorToast("Account number or password is incorrect");
      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(0, 2000 - elapsedTime);
      setTimeout(() => {
        setLoading(false);
      }, remainingTime);
    }
  };

  const handleAccountNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    setAccountNumber(value);
  };

  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
  };

  return (
    <div
      className="flex flex-col justify-between items-center min-h-screen bg-no-repeat bg-cover bg-center font-righteous"
      style={{ backgroundImage: `url(${loginBackground})` }}
    >
      <a href="#main-content" className="sr-only focus:not-sr-only">
        Skip to main content
      </a>
      <header className="w-full max-w-md px-8 mt-[12vh] text-left">
        <h1 className="text-[4.8em] font-bold text-white">Verdant</h1>
        <div className="text-[4em] font-bold text-white mt-[-5vh]">Pay</div>
      </header>
      <main
        id="main-content"
        className="w-full max-w-md px-8 py-6 mb-20 text-left"
      >
        <h2 className="sr-only">Login Form</h2>
        <form onSubmit={handleSubmit} noValidate>
          <div className="mb-4">
            <label htmlFor="accountNumber" className="sr-only">
              Account Number
            </label>
            <InputField
              id="accountNumber"
              type="text"
              placeholder="Account Number"
              value={accountNumber}
              onChange={handleAccountNumberChange}
              onKeyPress={handleKeyPress}
              name="accountNumber"
              inputMode="numeric"
              pattern="[0-9]*"
              maxLength={8}
            />
          </div>
          <div className="mb-10">
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <InputField
              id="password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
            />
          </div>

          <div>
            <Button
              label="Login"
              onClick={handleSubmit}
              variant="primary"
              fullWidth
              loading={loading}
              aria-live="polite"
            />
          </div>
        </form>
        <div className="mt-6 text-center">
          <span className="text-sm text-gray-700">Don't have an account? </span>
          <Link
            to="/register"
            className="text-sm text-blue-600 hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Register
          </Link>
        </div>
      </main>
    </div>
  );
}

export default LoginPage;
