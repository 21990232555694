import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import loginBackground from "../assets/images/login-background.png";
import InputField from "../components/InputField";
import Button from "../components/Button";
import { useRegistration } from "../contexts/RegistrationContext";
import { showErrorToast } from "../components/Toast";

function RegisterPage() {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { setAccountNumber } = useRegistration();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const validateForm = () => {
    if (name.trim().length < 2) {
      showErrorToast("Name must be at least 2 characters long");
      return false;
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      showErrorToast(
        "Password must be at least 8 characters long and include uppercase, lowercase, number, and special character"
      );
      return false;
    }

    if (password !== confirmPassword) {
      showErrorToast("Passwords do not match");
      return false;
    }

    if (!agreeToTerms) {
      showErrorToast("Please agree to the Terms and Conditions");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    document.getElementById("form-status").textContent =
      "Submitting registration...";
    const startTime = Date.now();

    try {
      const apiCall = await fetch("https://api.qmb.org.uk/register", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ name: name, password: password }),
      });

      if (apiCall.status !== 200) {
        throw new Error("Registration failed!");
      }

      const response = await apiCall.json();
      const generatedAccountNumber = response["accountNumber"];

      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(0, 2000 - elapsedTime);

      setTimeout(() => {
        setAccountNumber(generatedAccountNumber);
        navigate("/account-number");
      }, remainingTime);
    } catch (error) {
      console.error(error);
      showErrorToast("Registration failed. Please try again.");
    } finally {
      setLoading(false);
      document.getElementById("form-status").textContent = "";
    }
  };

  return (
    <div
      className="flex flex-col justify-between items-center min-h-screen bg-no-repeat bg-cover bg-center font-righteous"
      style={{ backgroundImage: `url(${loginBackground})` }}
    >
      <a href="#main-content" className="sr-only focus:not-sr-only">
        Skip to main content
      </a>
      <header className="w-full max-w-md px-8 mt-[12vh] text-left">
        <h1 className="text-[4.8em] font-bold text-white">Verdant</h1>
        <div className="text-[4em] font-bold text-white mt-[-5vh]">Pay</div>
      </header>
      <main
        id="main-content"
        className="w-full max-w-md px-8 py-6 mb-20 text-left"
      >
        <h2 className="sr-only">Registration Form</h2>
        <form onSubmit={handleSubmit} noValidate>
          <div className="mb-2">
            <label htmlFor="name" className="sr-only">
              Name
            </label>
            <InputField
              id="name"
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="name"
            />
          </div>
          <div className="mb-2">
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <InputField
              id="password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
            />
          </div>
          <div className="mb-10">
            <label htmlFor="confirmPassword" className="sr-only">
              Confirm Password
            </label>
            <InputField
              id="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              name="confirmPassword"
            />
          </div>
          <div className="mb-10 ml-2">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={agreeToTerms}
                onChange={(e) => setAgreeToTerms(e.target.checked)}
                className="mr-2"
              />
              <span className="text-sm text-gray-700">
                I agree to the{" "}
                <Link to="/terms" className="text-blue-600 hover:underline">
                  Terms and Conditions
                </Link>
              </span>
            </label>
          </div>
          <div>
            <Button
              label="Register"
              onClick={handleSubmit}
              variant="primary"
              fullWidth
              loading={loading}
              aria-live="polite"
            />
          </div>
        </form>
        <div id="form-status" className="sr-only" aria-live="polite"></div>
        <div className="mt-6 text-center">
          <span className="text-sm text-gray-700">
            Already have an account?{" "}
          </span>
          <Link
            to="/"
            className="text-sm text-blue-600 hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Login
          </Link>
        </div>
      </main>
    </div>
  );
}

export default RegisterPage;
