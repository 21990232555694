import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../contexts/LoginContext";
import TopBar from "../components/TopBar";
import Button from "../components/Button";
import InputField from "../components/InputField";
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiGift, mdiLoading } from "@mdi/js";
import { showErrorToast, showSuccessToast } from "../components/Toast";
import { useCreateChallenge } from "../contexts/CreateChallengeContext";
import { useLocation } from "react-router-dom";

function AdminAddRewardPage() {
  const { darkMode, token } = useLogin();
  const navigate = useNavigate();
  const location = useLocation();
  const { setShowRewardModal } = useCreateChallenge();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    rewardType: "",
    rewardText: "",
    rewardTitle: "",
    rewardQuantity: null,
    rewardCost: null,
    minimumLevel: null,
  });

  const fromChallenge = location.state?.fromChallenge;

  const handleInputChange = (e) => {
    const value =
      e.target.type === "number" ? Number(e.target.value) : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const validateForm = () => {
    if (
      !formData.rewardText ||
      !formData.rewardType ||
      formData.rewardCost === undefined
    ) {
      showErrorToast("Please fill in all required fields");
      return false;
    }
    if (typeof formData.rewardCost !== "number" || formData.rewardCost < 0) {
      showErrorToast("Reward cost must be a non-negative number");
      return false;
    }
    if (
      formData.minimumLevel !== null &&
      (typeof formData.minimumLevel !== "number" || formData.minimumLevel < 0)
    ) {
      showErrorToast("Minimum level must be a non-negative number or null");
      return false;
    }
    if (
      formData.rewardQuantity !== null &&
      (typeof formData.rewardQuantity !== "number" ||
        formData.rewardQuantity < 0)
    ) {
      showErrorToast("Reward quantity must be a non-negative number or null");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);

    console.log(
      "Sending request with data:",
      JSON.stringify(formData, null, 2)
    );
    console.log("Authorization token:", token);

    try {
      const response = await fetch("https://api.qmb.org.uk/createReward", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          rewardType: formData.rewardType,
          rewardText: formData.rewardText,
          rewardTitle: formData.rewardTitle,
          rewardQuantity: formData.rewardQuantity,
          rewardCost: formData.rewardCost,
          minimumLevel: formData.minimumLevel,
        }),
      });

      console.log("Response status:", response.status);
      console.log(
        "Response headers:",
        JSON.stringify(Object.fromEntries(response.headers), null, 2)
      );

      const responseText = await response.text();
      console.log("Raw response body:", responseText);

      let responseData;
      if (responseText) {
        try {
          responseData = JSON.parse(responseText);
        } catch (parseError) {
          console.error("Error parsing response JSON:", parseError);
          responseData = { message: responseText };
        }
      } else {
        responseData = { message: "No response body" };
      }

      if (!response.ok) {
        throw new Error(
          `HTTP error! status: ${response.status}, message: ${
            responseData.message || "Unknown error"
          }`
        );
      }

      showSuccessToast("Reward added successfully");
      if (fromChallenge) {
        setShowRewardModal(true);
        navigate("/admin/add-challenge");
      } else {
        navigate("/admin/rewards");
      }
    } catch (error) {
      console.error("Error adding reward:", error);
      showErrorToast(`Failed to add reward: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const backIcon = (
    <Icon
      path={mdiArrowLeft}
      size={1.5}
      className="text-white cursor-pointer"
      onClick={() => fromChallenge ? navigate("/admin/add-challenge") : navigate("/admin/rewards")}
    />
  );

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900" : "bg-gray-100"
      } font-righteous pb-24 relative`}
    >
      <div className="absolute top-0 left-0 right-0 h-[20vh] bg-[#319D32] rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)] z-0"></div>
      <div className="relative z-10 container mx-auto px-4 py-4">
        <TopBar leftIcon={backIcon} />
        <h1 className="text-3xl font-bold mt-4 text-white text-center">
          Add New Reward
        </h1>
      </div>

      <main className="relative z-10 container mx-auto px-4 mt-12">
        <form onSubmit={handleSubmit} className="space-y-4">
          <InputField
            id="rewardType"
            type="text"
            placeholder="Reward Type"
            value={formData.rewardType}
            onChange={handleInputChange}
            name="rewardType"
            required
          />
          <InputField
            id="rewardTitle"
            type="text"
            placeholder="Reward Title"
            value={formData.rewardTitle}
            onChange={handleInputChange}
            name="rewardTitle"
            required
          />
          <InputField
            id="rewardText"
            type="text"
            placeholder="Reward Text"
            value={formData.rewardText}
            onChange={handleInputChange}
            name="rewardText"
            required
          />
          <InputField
            id="rewardQuantity"
            type="number"
            placeholder="Reward Quantity"
            value={formData.rewardQuantity}
            onChange={handleInputChange}
            name="rewardQuantity"
            required
          />
          <InputField
            id="rewardCost"
            type="number"
            placeholder="Reward Cost"
            value={formData.rewardCost}
            onChange={handleInputChange}
            name="rewardCost"
            required
          />
          <InputField
            id="minimumLevel"
            type="number"
            placeholder="Minimum Level"
            value={formData.minimumLevel}
            onChange={handleInputChange}
            name="minimumLevel"
            required
          />
        </form>
        <div className="mt-8">
          <Button
            label="Add Reward"
            onClick={handleSubmit}
            variant="primary"
            fullWidth
            disabled={isLoading}
            loading={isLoading}
          ></Button>
        </div>
      </main>
    </div>
  );
}

export default AdminAddRewardPage;