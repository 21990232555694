import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";
import Icon from "@mdi/react";
import {
  mdiMenu,
  mdiCurrencyGbp,
  mdiClockOutline,
  mdiCreditCardOutline,
  mdiAccountSearch,
} from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";

function BankPage() {
  const { token, userData, darkMode } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (token === null) {
      navigate("/");
    }
  }, [token, navigate]);

  const menuIcon = (
    <Icon
      path={mdiMenu}
      size={1.5}
      className="text-white cursor-pointer"
      aria-label="Menu"
    />
  );

  const bankingOptions = [
    {
      icon: mdiCurrencyGbp,
      title: "Send Money",
      description: "Quick and secure transfers",
      onClick: () => navigate("/send-money"),
    },
    {
      icon: mdiAccountSearch,
      title: "Search Payees",
      description: "Find and manage your payees",
      onClick: () => navigate("/search-payees"),
    },
    {
      icon: mdiClockOutline,
      title: "Transaction History",
      description: "View your past transactions",
      onClick: () => navigate("/transaction-history"),
    },
  ];

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900" : "bg-gray-100"
      } font-righteous pb-24 relative`}
    >
      <div className="absolute top-0 left-0 right-0 h-1/3 bg-[#319D32] rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)] z-0"></div>
      <div className="relative z-10 container mx-auto px-4 py-4">
        <div
          className={`mt-6 ${
            darkMode ? "bg-gray-800" : "bg-white"
          } rounded-[30px] shadow-md p-6 w-84`}
        >
          <p
            id="balance-label"
            className={`text-lg ${
              darkMode ? "text-gray-300" : "text-gray-600"
            }`}
          >
            Current Balance
          </p>
          <p
            className={`text-4xl font-bold mt-2 ${
              darkMode ? "text-[#ffffff]" : "text-[#1a5a1c]"
            }`}
            aria-live="polite"
            aria-labelledby="balance-label"
          >
            £{((userData?.balance ?? 0) / 100.0).toFixed(2)}
          </p>
        </div>
      </div>

      <main className="relative z-10 container mx-auto px-4 mt-12">
        <nav aria-label="Banking options">
          <ul className="grid grid-cols-2 gap-4 list-none p-0">
            {bankingOptions.map((option, index) => (
              <li
                key={index}
                className={
                  index === bankingOptions.length - 1 ? "col-span-2" : ""
                }
              >
                <button
                  className={`w-full h-full ${
                    darkMode
                      ? "bg-gray-800 hover:bg-gray-700"
                      : "bg-white hover:bg-gray-50"
                  } rounded-[20px] shadow-md p-4 py-6 flex flex-col items-center justify-center cursor-pointer transition-colors focus:outline-none focus:ring-2 focus:ring-[#319D32] focus:ring-opacity-50`}
                  onClick={option.onClick}
                  aria-label={`${option.title}: ${option.description}`}
                >
                  <div
                    className="bg-[#319D32] p-3 rounded-full mb-3"
                    aria-hidden="true"
                  >
                    <Icon
                      path={option.icon}
                      size={1.5}
                      className="text-white"
                    />
                  </div>
                  <h2
                    className={`text-lg font-bold ${
                      darkMode ? "text-gray-200" : "text-gray-700"
                    } text-center`}
                  >
                    {option.title}
                  </h2>
                  <p
                    className={`text-xs ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    } text-center mt-1`}
                  >
                    {option.description}
                  </p>
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </main>

      <Navbar />
    </div>
  );
}

export default BankPage;
