import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../contexts/LoginContext";
import TopBar from "../components/TopBar";
import Button from "../components/Button";
import InputField from "../components/InputField";
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiAccountPlus } from "@mdi/js";
import { showErrorToast, showSuccessToast } from "../components/Toast";

function AdminAddUserPage() {
  const { darkMode, token } = useLogin();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    if (!formData.name.trim()) {
      showErrorToast("Name is required");
      return false;
    }
    if (!formData.email.trim()) {
      showErrorToast("Email is required");
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      showErrorToast("Invalid email format");
      return false;
    }
    if (formData.password.length < 8) {
      showErrorToast("Password must be at least 8 characters long");
      return false;
    }
    if (formData.password !== formData.confirmPassword) {
      showErrorToast("Passwords do not match");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await fetch("https://api.qmb.org.uk/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        showSuccessToast("User added successfully");
        navigate("/admin/users");
      } else {
        const data = await response.json();
        showErrorToast(data.message || "Failed to add user");
      }
    } catch (error) {
      showErrorToast("An error occurred. Please try again.");
    }
  };

  const backIcon = (
    <Icon
      path={mdiArrowLeft}
      size={1.5}
      className="text-white cursor-pointer"
      onClick={() => navigate(-1)}
    />
  );

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900" : "bg-gray-100"
      } font-righteous pb-24 relative`}
    >
      <div className="absolute top-0 left-0 right-0 h-[20vh] bg-[#319D32] rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)] z-0"></div>
      <div className="relative z-10 container mx-auto px-4 py-4">
        <TopBar leftIcon={backIcon} />
        <h1 className="text-3xl font-bold mt-4 text-white text-center">
          Add New User
        </h1>
      </div>

      <main className="relative z-10 container mx-auto px-4 mt-12">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="sr-only">
              Name
            </label>
            <InputField
              id="name"
              type="text"
              placeholder="Name"
              value={formData.name}
              onChange={handleInputChange}
              name="name"
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <InputField
              id="password"
              type="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
              name="password"
              required
            />
          </div>
          <div>
            <label htmlFor="confirmPassword" className="sr-only">
              Confirm Password
            </label>
            <InputField
              id="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              name="confirmPassword"
              required
            />
          </div>
        </form>
        <div className="mt-12">
          <Button
            label="Add User"
            onClick={handleSubmit}
            variant="primary"
            fullWidth
          >
            <Icon path={mdiAccountPlus} size={1} className="mr-2" />
          </Button>
        </div>
      </main>
    </div>
  );
}

export default AdminAddUserPage;
