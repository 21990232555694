import React, {
  useRef,
  createContext,
  useState,
  useContext,
  useEffect,
} from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";

const LoginContext = createContext();

export const useLogin = () => useContext(LoginContext);

export const LoginProvider = ({ children }) => {
  const [token, updateToken] = useState(null);
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("darkMode");
    return savedMode ? JSON.parse(savedMode) : false;
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const [watchedAccounts, setWatchedAccounts] = useState(new Set());

  const socketUrl = token ? `wss://socket.qmb.org.uk/?token=${token}` : null;
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    if (lastMessage !== null) {
      const newData = { ...userData };
      for (const [key, value] of Object.entries(JSON.parse(lastMessage.data))) {
        console.log(key);
        newData[key] = value;
      }
      setUserData(newData);
    }
  }, [lastMessage]);

  const updateUserData = async (token) => {
    const userDataRequest = await fetch(
      "https://api.qmb.org.uk/userData?balance=true&transactionHistory=true&greenScore=true&challenges=true&payees=true&leaderboard=true&watchedAccounts=true&shop=true&rewards=true",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
        method: "GET",
      }
    );

    const data = await userDataRequest.json();
    setUserData(data);
    // Ensure watchedAccounts is an array before creating a Set
    const watchedAccountsArray = Array.isArray(data.watchedAccounts)
      ? data.watchedAccounts
      : [];
    setWatchedAccounts(new Set(watchedAccountsArray));
    console.log("Initialized watched accounts:", watchedAccountsArray);
  };

  const watchAccount = async (accountToWatch) => {
    try {
      console.log("Attempting to watch account:", accountToWatch);
      if (!accountToWatch) {
        throw new Error("Invalid account to watch");
      }
      const response = await fetch("https://api.qmb.org.uk/watchAccount", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ accountToWatch }),
      });

      let responseData;
      const responseText = await response.text();
      try {
        responseData = responseText ? JSON.parse(responseText) : {};
      } catch (error) {
        console.error("Failed to parse response:", responseText);
        responseData = {};
      }

      console.log("Watch account response:", responseData);

      if (response.ok) {
        setWatchedAccounts((prev) => {
          const newSet = new Set(prev);
          newSet.add(accountToWatch);
          console.log("Updated watched accounts:", Array.from(newSet));
          return newSet;
        });
      } else {
        console.error("Failed to watch account:", responseData);
        throw new Error(responseData.message || "Failed to watch account");
      }
    } catch (error) {
      console.error("Error watching account:", error);
      throw error;
    }
  };

  const unwatchAccount = async (accountToUnwatch) => {
    try {
      const response = await fetch("https://api.qmb.org.uk/unWatchAccount", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ accountToUnwatch }),
      });
      if (response.ok) {
        setWatchedAccounts((prev) => {
          const newSet = new Set(prev);
          newSet.delete(accountToUnwatch);
          console.log("Updated watched accounts:", Array.from(newSet));
          return newSet;
        });
      } else {
        console.error("Failed to unwatch account");
        throw new Error("Failed to unwatch account");
      }
    } catch (error) {
      console.error("Error unwatching account:", error);
      throw error;
    }
  };

  const setToken = (newToken) => {
    updateToken(newToken);
  };

  const toggleDarkMode = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    localStorage.setItem("darkMode", JSON.stringify(newMode));
  };

  return (
    <LoginContext.Provider
      value={{
        token,
        setToken,
        userData,
        updateUserData,
        isLoading,
        setIsLoading,
        darkMode,
        toggleDarkMode,
        isAdmin,
        setIsAdmin,
        watchedAccounts,
        watchAccount,
        unwatchAccount,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
