import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";
import Icon from "@mdi/react";
import { mdiMenu, mdiLeaf } from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const { token, userData, updateUserData, isLoading, setIsLoading, darkMode } =
    useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  useEffect(() => {
    if (token === null) {
      navigate("/");
    } else if (isLoading) {
      const fetchData = async () => {
        try {
          await updateUserData(token);
        } catch (error) {
          console.error("Error updating user data:", error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }
  }, [token, navigate, updateUserData, isLoading, setIsLoading]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-[#319D32] flex flex-col items-center justify-center">
        <svg
          className="w-16 h-16 text-white animate-bounce"
          viewBox="0 0 24 24"
        >
          <path fill="currentColor" d={mdiLeaf} />
        </svg>
        <p className="text-white text-xl mt-4 font-righteous">
          Loading your eco-friendly banking...
        </p>
      </div>
    );
  }

  const menuIcon = (
    <Icon path={mdiMenu} size={1.5} className="text-white cursor-pointer" />
  );

  // Function to get the last 3 transactions
  const getRecentTransactions = () => {
    if (
      userData["transactionHistory"] &&
      userData["transactionHistory"].length > 0
    ) {
      return userData["transactionHistory"].slice(0, 3);
    }
    return [];
  };

  // Calculate the height of the transactions card
  const getTransactionCardHeight = () => {
    const transactions = getRecentTransactions();
    if (transactions.length === 0) return "h-[15vh]";
    if (transactions.length === 1) return "h-[16vh]";
    if (transactions.length === 2) return "h-[20vh]";
    return "h-[28vh]";
  };

  // Function to calculate the streak based on consecutive green transactions
  const calculateGreenStreak = () => {
    if (
      !userData["transactionHistory"] ||
      userData["transactionHistory"].length === 0
    ) {
      return 0;
    }

    let currentStreak = 0;
    let maxStreak = 0;

    // Sort transactions by date in descending order
    const sortedTransactions = [...userData["transactionHistory"]].sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );

    for (const transaction of sortedTransactions) {
      if (transaction.totalScore >= 0.7) {
        currentStreak++;
        maxStreak = Math.max(maxStreak, currentStreak);
      } else {
        break; // Stop counting when a non-green transaction is encountered
      }
    }

    return maxStreak;
  };

  // Function to calculate trees planted
  const calculateTreesPlanted = () => {
    return (
      userData["transactionHistory"]?.filter((t) => t.totalScore >= 0.7)
        .length || 0
    );
  };

  // Helper function to get payee name
  const getPayeeName = (accountNumber) => {
    if (accountNumber == userData.accountNumber) {
      return "Your Account";
    }
    const payee = userData.payees?.find(
      (p) => p.accountNumber == accountNumber
    );
    return payee ? payee.name : `Account ${accountNumber}`;
  };

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900 text-white" : "bg-gray-100"
      } font-righteous pb-24 relative`}
    >
      <div className="absolute top-0 left-0 right-0 h-[27vh] bg-[#319D32] rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)] z-0"></div>
      <div className="relative z-10 container mx-auto px-4 py-4">
        <h1 className="text-3xl font-bold mb-3 text-white text-center">
          Welcome Back!
        </h1>
      </div>
      <div
        className={`${
          darkMode ? "bg-gray-800" : "bg-white"
        } rounded-[30px] shadow-[0_4px_2px_rgba(0,0,0,0.1)] p-6 mt-[0vh] mx-auto relative w-3/4 h-[5vh] z-20 flex items-center justify-center`}
      >
        <h2
          className={`text-xl font-bold ${
            darkMode ? "text-gray-300" : "text-gray-600"
          } flex items-center`}
        >
          Green Score:{" "}
          <span className="text-[1.5em] font-bold text-[#319D32] ml-2 text-shadow-md">
            {userData["greenScore"] ?? "N/A"}
          </span>
        </h2>
      </div>
      <div className="flex flex-col gap-4 mt-[7vh] mx-auto relative w-[90%] z-20">
        <div className="flex justify-between gap-4">
          <div
            className={`${
              darkMode ? "bg-gray-800" : "bg-white"
            } rounded-[30px] shadow-[0_4px_2px_rgba(0,0,0,0.1)] p-6 w-1/2 h-[15vh] flex flex-col items-start justify-center`}
          >
            <h3
              className={`text-xl font-bold ${
                darkMode ? "text-gray-300" : "text-gray-500"
              }`}
            >
              {userData["name"] ?? "User"}
            </h3>
            <p
              className={`text-[1.5em] font-bold ${
                darkMode ? "text-gray-100" : "text-gray-700"
              }`}
            >
              £{(userData["balance"] / 100.0).toFixed(2) ?? "0.00"}
            </p>
            <p
              className={`text-[0.8em] font-bold ${
                darkMode ? "text-gray-400" : "text-gray-500"
              }`}
            >
              Account Balance
            </p>
          </div>
          <div
            className={`${
              darkMode ? "bg-gray-800" : "bg-white"
            } rounded-[30px] shadow-[0_4px_2px_rgba(0,0,0,0.1)] p-6 w-1/2 h-[15vh] flex flex-col items-start justify-center`}
          >
            <h3
              className={`text-[1.3em] font-bold ${
                darkMode ? "text-gray-300" : "text-gray-500"
              }`}
            >
              Green Streak
            </h3>
            <p
              className={`text-[1.5em] font-bold ${
                darkMode ? "text-gray-100" : "text-gray-700"
              }`}
            >
              {calculateGreenStreak()}
            </p>
          </div>
        </div>
        <div
          className={`${
            darkMode ? "bg-gray-800" : "bg-white"
          } rounded-[30px] shadow-[0_4px_2px_rgba(0,0,0,0.1)] p-6 w-full ${getTransactionCardHeight()}`}
        >
          <h2
            className={`text-xl font-bold ${
              darkMode ? "text-gray-200" : "text-gray-700"
            } mb-2`}
          >
            Recent Transactions
          </h2>
          {getRecentTransactions().map((transaction, index) => (
            <div key={index} className="text-sm mb-4">
              <div className="flex items-center justify-between">
                <div>
                  <p
                    className={`font-bold ${
                      darkMode ? "text-gray-200" : "text-gray-800"
                    }`}
                  >
                    {transaction.fromAccount == userData.accountNumber
                      ? `${getPayeeName(transaction.toAccount)}`
                      : `${getPayeeName(transaction.fromAccount)}`}
                  </p>
                  <p
                    className={`text-xs ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    {new Date(transaction.timestamp).toLocaleDateString()}
                  </p>
                </div>
                <p
                  className={`font-bold ${
                    transaction.fromAccount == userData.accountNumber
                      ? "text-red-600"
                      : "text-green-500"
                  }`}
                >
                  {transaction.fromAccount == userData.accountNumber
                    ? "- "
                    : "+ "}
                  £{(transaction.amount / 100).toFixed(2)}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div
          className={`${
            darkMode ? "bg-gray-800" : "bg-white"
          } rounded-[30px] shadow-[0_4px_2px_rgba(0,0,0,0.1)] p-6 w-full min-h-[15vh]`}
        >
          <h2
            className={`text-xl font-bold ${
              darkMode ? "text-gray-200" : "text-gray-700"
            } mb-4`}
          >
            Current Challenge
          </h2>
          {userData.challenges &&
          userData.challenges.filter((challenge) => challenge.started).length >
            0 ? (
            userData.challenges
              .filter((challenge) => challenge.started)
              .map((challenge, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center mb-2"
                >
                  <h3
                    className={`text-md font-bold ${
                      darkMode ? "text-gray-300" : "text-gray-500"
                    }`}
                  >
                    {challenge.title}
                  </h3>
                  <p
                    className={`text-2xl font-bold ${
                      darkMode ? "text-gray-100" : "text-gray-700"
                    }`}
                  >
                    {challenge.completionText}
                  </p>
                </div>
              ))
          ) : (
            <p
              className={`${
                darkMode ? "text-gray-400" : "text-gray-500"
              } text-center`}
            >
              No active challenges
            </p>
          )}
        </div>
        <div className="flex justify-between gap-4">
          <div
            className={`${
              darkMode ? "bg-gray-800" : "bg-white"
            } rounded-[30px] shadow-[0_4px_2px_rgba(0,0,0,0.1)] p-6 w-1/2 h-[15vh] flex flex-col items-start justify-center`}
          >
            <h3
              className={`text-[1.2em] font-bold ${
                darkMode ? "text-gray-300" : "text-gray-500"
              }`}
            >
              Trees Planted
            </h3>
            <p
              className={`text-[1.5em] font-bold ${
                darkMode ? "text-gray-100" : "text-gray-700"
              }`}
            >
              {calculateTreesPlanted()}
            </p>
          </div>
          <div
            className={`${
              darkMode ? "bg-gray-800" : "bg-white"
            } rounded-[30px] shadow-[0_4px_2px_rgba(0,0,0,0.1)] p-6 w-1/2 h-[15vh]`}
          ></div>
        </div>
      </div>
      <Navbar />
    </div>
  );
}

export default HomePage;
