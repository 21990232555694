import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TopBar from "../components/TopBar";
import Icon from "@mdi/react";
import {
  mdiArrowLeft,
  mdiMenu,
  mdiLeaf,
  mdiStore,
  mdiCalendarClock,
  mdiCurrencyGbp,
  mdiBarcode,
  mdiAccountCircle,
  mdiChevronRight, // Add this line
} from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";

function TransactionDetailPage() {
  const navigate = useNavigate();

  const { token, userData, darkMode } = useLogin();

  const { id } = useParams();
  const [transaction, setTransaction] = useState({});

  useEffect(() => {
    if (token === null) {
      navigate("/");
    }
    for (const transaction of userData["transactionHistory"]) {
      if (transaction.transactionID != id) {
        continue;
      }
      const payeeName = getPayeeName(
        transaction.fromAccount === userData.accountNumber
          ? transaction.fromAccount
          : transaction.toAccount
      );
      setTransaction({
        id: transaction.transactionID,
        fromAccount: transaction.fromAccount,
        toAccount: transaction.toAccount,
        payeeName: payeeName,
        amount: (transaction.amount / 100).toFixed(2),
        timestamp: transaction.timestamp,
        greenScore: transaction.totalScore >= 0.5 ? 10 : -2,
        category: transaction.category,
        companyRagScore: `Carbon Emissions: ${transaction.carbonEmissions}, Waste Management: ${transaction.wasteManagement}, Sustainability Practices: ${transaction.sustainabilityPractices}`,
        reference: transaction.reference,
        betterChoices: transaction.betterChoices,
        betterChoices: transaction.betterChoices,
      });
    }
  }, [id, token, userData]);

  // Helper function to get payee name
  const getPayeeName = (accountNumber) => {
    if (accountNumber == userData.accountNumber) {
      return "Your Account";
    }
    const payee = userData.payees?.find(
      (p) => p.accountNumber == accountNumber
    );
    return payee ? payee.name : `Account ${accountNumber}`;
  };

  const backIcon = (
    <Icon
      path={mdiArrowLeft}
      size={1.5}
      className="text-white cursor-pointer"
      onClick={() => navigate(-1)}
    />
  );

  const menuIcon = (
    <Icon path={mdiMenu} size={1.5} className="text-white cursor-pointer" />
  );

  if (!transaction.id) {
    return (
      <div className={`${darkMode ? "text-white" : "text-gray-800"}`}>
        Loading...
      </div>
    );
  }

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900" : "bg-gray-100"
      } font-righteous pb-24 relative`}
    >
      <div className="absolute top-0 left-0 right-0 h-1/4 bg-[#319D32] rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)] z-0"></div>
      <div className="relative z-10 container mx-auto px-4 py-4">
        <TopBar leftIcon={backIcon} rightIcons={[menuIcon]} />
        <h1 className="text-3xl font-bold mt-4 text-white text-center">
          Transaction Details
        </h1>
      </div>

      <div className="relative z-10 container mx-auto px-4 mt-6">
        <div
          className={`${
            darkMode ? "bg-gray-800" : "bg-white"
          } rounded-[30px] shadow-[0_4px_2px_rgba(0,0,0,0.1)] p-6`}
        >
          {transaction.fromAccount == userData["accountNumber"] ? (
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center">
                <Icon path={mdiLeaf} size={1} className="text-green-500 mr-1" />
                <span className="text-lg font-bold text-green-500">
                  {transaction.greenScore}
                </span>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="space-y-4">
            <div className="flex items-center">
              <Icon
                path={mdiAccountCircle}
                size={1}
                className={`${
                  darkMode ? "text-gray-400" : "text-gray-500"
                } mr-4`}
              />
              <div>
                <p
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-500"
                  }`}
                >
                  {transaction.fromAccount == userData["accountNumber"]
                    ? "Sent To"
                    : "Received From"}
                </p>
                <p
                  className={`text-lg font-bold ${
                    darkMode ? "text-gray-200" : "text-gray-800"
                  }`}
                >
                  {transaction.payeeName}
                </p>
              </div>
            </div>

            <div className="flex items-center">
              <Icon
                path={mdiCurrencyGbp}
                size={1}
                className={`${
                  darkMode ? "text-gray-400" : "text-gray-500"
                } mr-4`}
              />
              <div>
                <p
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-500"
                  }`}
                >
                  Amount
                </p>
                <p
                  className={`font-bold ${
                    transaction.fromAccount == userData["accountNumber"]
                      ? "text-red-600"
                      : "text-green-500"
                  }`}
                >
                  {transaction.fromAccount == userData["accountNumber"]
                    ? "-"
                    : "+"}{" "}
                  £{transaction.amount}
                </p>
              </div>
            </div>

            <div className="flex items-center">
              <Icon
                path={mdiCalendarClock}
                size={1}
                className={`${
                  darkMode ? "text-gray-400" : "text-gray-500"
                } mr-4`}
              />
              <div>
                <p
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-500"
                  }`}
                >
                  Date & Time
                </p>
                <p
                  className={`text-lg font-bold ${
                    darkMode ? "text-gray-200" : "text-gray-800"
                  }`}
                >
                  {transaction.timestamp}
                </p>
              </div>
            </div>
            {transaction.category != null ? (
              <>
                <div
                  className={`pt-4 border-t ${
                    darkMode ? "border-gray-700" : "border-gray-200"
                  }`}
                >
                  <p
                    className={`text-sm ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    Category
                  </p>
                  <p
                    className={`text-lg font-bold ${
                      darkMode ? "text-gray-200" : "text-gray-800"
                    }`}
                  >
                    {transaction.category}
                  </p>
                </div>

                <div>
                  <p
                    className={`text-sm ${
                      darkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    Company RAG Score
                  </p>
                  <div className="text-lg font-bold">
                    {transaction.companyRagScore
                      .split(",")
                      .map((score, index) => {
                        const [label, value] = score
                          .split(":")
                          .map((s) => s.trim());
                        const numericValue = parseFloat(value);
                        let textColor = darkMode
                          ? "text-gray-200"
                          : "text-gray-800";
                        if (numericValue < 3) textColor = "text-red-600";
                        else if (numericValue < 7)
                          textColor = "text-orange-500";
                        else textColor = "text-green-500";
                        return (
                          <React.Fragment key={index}>
                            <span
                              className={
                                darkMode ? "text-gray-300" : "text-gray-700"
                              }
                            >
                              {label}:{" "}
                            </span>
                            <span className={textColor}>{value}</span>
                            {index <
                              transaction.companyRagScore.split(",").length -
                                1 && <br />}
                          </React.Fragment>
                        );
                      })}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {transaction.betterChoices.length > 0 ? (
              <div>
                <p
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-500"
                  }`}
                >
                  Better Choices
                </p>
                {transaction.betterChoices.map((choice, index) => (
                  <button
                    key={index}
                    className={`text-lg font-bold ${
                      darkMode
                        ? "text-gray-200 hover:text-white"
                        : "text-gray-800 hover:text-gray-600"
                    } transition-colors duration-200 ease-in-out block w-full text-left flex justify-between items-center py-2`}
                    onClick={() =>
                      navigate(`/payee-details/${choice.accountNumber}`)
                    }
                  >
                    <span>{choice.accountName}</span>
                    <Icon
                      path={mdiChevronRight}
                      size={1}
                      className={`${
                        darkMode ? "text-gray-400" : "text-gray-500"
                      }`}
                    />
                  </button>
                ))}
              </div>
            ) : (
              <></>
            )}

            <div className="flex items-center">
              <Icon
                path={mdiBarcode}
                size={1}
                className={`${
                  darkMode ? "text-gray-400" : "text-gray-500"
                } mr-4`}
              />
              <div>
                <p
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-500"
                  }`}
                >
                  Reference
                </p>
                <p
                  className={`text-lg font-bold ${
                    darkMode ? "text-gray-200" : "text-gray-800"
                  }`}
                >
                  {transaction.reference}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionDetailPage;
