import React from "react";

function Button({
  label,
  onClick,
  variant = "primary",
  className = "",
  fullWidth = false,
  loading = false,
}) {
  const baseStyle =
    "px-6 py-3 rounded-[1.3em] font-righteous text-lg transition-all duration-300 ease-in-out";

  const variants = {
    primary:
      "bg-[#319D32] text-white hover:bg-[#2A8A2C] active:bg-[#1A5A1C] active:transform active:scale-95 shadow-[0_2px_2px_rgba(0,0,0,0.3)]",
    secondary:
      "bg-gray-200 text-gray-800 hover:bg-gray-300 active:bg-gray-400 active:transform active:scale-95 shadow-[0_2px_2px_rgba(0,0,0,0.1)]",
    danger:
      "bg-[#cc342e] text-white hover:bg-[#b32e29] active:bg-[#8c231f] active:transform active:scale-95 shadow-[0_2px_2px_rgba(0,0,0,0.1)]",
  };

  const widthClass = fullWidth ? "w-full" : "";

  const buttonStyle = `${baseStyle} ${variants[variant]} ${widthClass} ${className}`;

  return (
    <button className={buttonStyle} onClick={onClick} disabled={loading}>
      {loading ? (
        <svg
          className="animate-spin h-5 w-5 text-white mx-auto"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : (
        label
      )}
    </button>
  );
}

export default Button;
