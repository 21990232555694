import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toast = () => {
  return (
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      limit={1}
    />
  );
};

const showToast = (message, type) => {
  toast.dismiss();
  toast[type](message);
};

export const showSuccessToast = (message) => showToast(message, "success");
export const showErrorToast = (message) => showToast(message, "error");
export const showInfoToast = (message) => showToast(message, "info");
export const showWarningToast = (message) => showToast(message, "warning");

export default Toast;
