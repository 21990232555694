import React from "react";
import Icon from "@mdi/react";
import { mdiTrophy, mdiCalendarRange } from "@mdi/js";

function ChallengeCard({ challenge, onClick, darkMode }) {
  return (
    <div
      className={`${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-[20px] shadow-md p-4 mb-4 cursor-pointer hover:shadow-lg transition-shadow`}
      onClick={onClick}
    >
      <div className="flex justify-between items-start mb-2">
        <div className="flex-1">
          <p className="text-sm font-semibold text-green-600 mb-1">
            {challenge.challengeType}
          </p>
          <p className={`text-base ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
            {challenge.title}
          </p>
        </div>
        <div className="flex items-center ml-2">
          <Icon path={mdiTrophy} size={0.8} className="text-yellow-500 mr-1" />
          <span className="text-sm font-bold text-yellow-500">
            {challenge.rewardText}
          </span>
        </div>
      </div>
      <div className={`flex items-center text-xs ${darkMode ? 'text-gray-400' : 'text-gray-500'} mt-2`}>
        <Icon path={mdiCalendarRange} size={0.6} className="mr-1" />
        <span>
          {challenge.startDate} - {challenge.endDate}
        </span>
      </div>
    </div>
  );
}

export default ChallengeCard;
