import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiMenu, mdiLeaf, mdiChevronRight } from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";

function TransactionHistoryPage() {
  const navigate = useNavigate();
  const { token, userData, darkMode } = useLogin();
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    if (token === null) {
      navigate("/");
    } else {
      // Fetch transactions from API
      setTransactions(userData["transactionHistory"] ?? []);
    }
  }, [token, navigate, userData]);

  const backIcon = (
    <Icon
      path={mdiArrowLeft}
      size={1.5}
      className="text-white cursor-pointer"
      onClick={() => navigate(-1)}
      aria-label="Go back"
    />
  );

  const menuIcon = (
    <Icon
      path={mdiMenu}
      size={1.5}
      className="text-white cursor-pointer"
      aria-label="Menu"
    />
  );

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }).format(amount / 100);
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  // Helper function to get payee name
  const getPayeeName = (accountNumber) => {
    if (accountNumber == userData.accountNumber) {
      return "Your Account";
    }
    const payee = userData.payees?.find(
      (p) => p.accountNumber == accountNumber
    );
    return payee ? payee.name : `Account ${accountNumber}`;
  };

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900' : 'bg-gray-100'} font-righteous pb-24`}>
      <div className="bg-[#319D32] rounded-b-[60px] shadow-lg pb-8">
        <div className="container mx-auto px-4 py-4">
          <TopBar leftIcon={backIcon} rightIcons={[menuIcon]} />
          <h1 className="text-3xl font-bold mt-4 text-white text-center">
            Transaction History
          </h1>
        </div>
      </div>

      <main className="container mx-auto px-4 mt-8">
        <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-[30px] shadow-lg overflow-hidden`}>
          {transactions.length === 0 ? (
            <p className={`p-6 text-center ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              No transactions found.
            </p>
          ) : (
            <ul className={`divide-y ${darkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
              {transactions.map((transaction) => (
                <li
                  key={transaction.transactionID}
                  className={`${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} transition-colors duration-150 ease-in-out`}
                >
                  <button
                    className="w-full text-left p-4 flex items-center justify-between focus:outline-none focus:ring-2 focus:ring-[#319D32] focus:ring-opacity-50"
                    onClick={() =>
                      navigate(
                        `/transaction-details/${transaction.transactionID}`
                      )
                    }
                    aria-label={`View details of transaction ${transaction.reference}`}
                  >
                    <div>
                      <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        {formatDate(transaction.timestamp)}
                      </p>
                      <p className={`font-semibold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                        {transaction.fromAccount == userData.accountNumber
                          ? `${getPayeeName(transaction.toAccount)}`
                          : `${getPayeeName(transaction.fromAccount)}`}
                      </p>
                      <p className={`text-xs ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        {transaction.reference}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <div className="text-right mr-4">
                        {transaction.totalScore != null && (
                          <div className="flex items-center justify-end mb-1">
                            <Icon
                              path={mdiLeaf}
                              size={0.7}
                              className="text-green-500 mr-1"
                            />
                            <span className={`text-xs ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                              {transaction.totalScore >= 0.5 ? "+10" : "-2"}
                            </span>
                          </div>
                        )}
                        <p
                          className={`font-bold ${
                            transaction.fromAccount == userData["accountNumber"]
                              ? "text-red-600"
                              : "text-green-500"
                          }`}
                        >
                          {transaction.fromAccount == userData["accountNumber"]
                            ? "- "
                            : "+ "}
                          {formatAmount(transaction.amount)}
                        </p>
                      </div>
                      <Icon
                        path={mdiChevronRight}
                        size={1}
                        className={darkMode ? 'text-gray-500' : 'text-gray-400'}
                        aria-hidden="true"
                      />
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </main>
    </div>
  );
}

export default TransactionHistoryPage;
