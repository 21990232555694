import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import Button from "../components/Button";
import Icon from "@mdi/react";
import {
  mdiArrowLeft,
  mdiMenu,
  mdiTrophy,
  mdiCalendarRange,
  mdiTarget,
  mdiCurrencyGbp,
} from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";

function ChallengeDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { token, userData, updateUserData, darkMode } = useLogin();
  const [isLoading, setIsLoading] = useState(false);

  const [challenge, setChallenge] = useState({});

  useEffect(() => {
    if (token === null) {
      navigate("/");
    }
    for (const challenge of userData["challenges"]) {
      if (challenge.challengeID != id) {
        continue;
      }
      setChallenge({
        id: challenge.challengeID,
        title: challenge.title,
        reward:
          (challenge.rewardQuantity == null
            ? ""
            : `${challenge.rewardQuantity} * `) +
          `${challenge.rewardText}(${challenge.rewardType})`,
        challengeType: challenge.challengeType,
        startDate: challenge.startDate,
        endDate: challenge.endDate,
        goalAmount: challenge.goalAmount,
        cost: challenge.cost,
        description: challenge.description,
        progress: 0,
        started: challenge.started, // Add this line
      });
    }
  }, [challenge.id]);

  const backIcon = (
    <Icon
      path={mdiArrowLeft}
      size={1.5}
      className="text-white cursor-pointer"
      onClick={() => navigate(-1)}
    />
  );

  const menuIcon = (
    <Icon path={mdiMenu} size={1.5} className="text-white cursor-pointer" />
  );

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900" : "bg-gray-100"
      } font-righteous pb-24 relative`}
    >
      <div className="absolute top-0 left-0 right-0 h-1/4 bg-[#319D32] rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)] z-0"></div>
      <div className="relative z-10 container mx-auto px-4 py-4">
        <TopBar leftIcon={backIcon} rightIcons={[menuIcon]} />
        <h1 className="text-3xl font-bold mt-4 text-white text-center">
          Challenge Details
        </h1>
      </div>

      <div className="relative z-10 container mx-auto px-4 mt-8">
        <div
          className={`${
            darkMode ? "bg-gray-800" : "bg-white"
          } rounded-[30px] shadow-md p-6`}
        >
          <h2
            className={`text-2xl font-bold ${
              darkMode ? "text-gray-200" : "text-gray-800"
            } mb-2`}
          >
            {challenge.title}
          </h2>
          <p className="text-sm font-semibold text-green-600 mb-4">
            {challenge.challengeType}
          </p>

          <p className={`${darkMode ? "text-gray-300" : "text-gray-600"} mb-6`}>
            {challenge.description}
          </p>

          <div className="space-y-4">
            <div className="flex items-center">
              <Icon
                path={mdiTrophy}
                size={1}
                className="text-yellow-500 mr-4"
              />
              <div>
                <p
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-500"
                  }`}
                >
                  Reward
                </p>
                <p
                  className={`text-lg font-bold ${
                    darkMode ? "text-gray-200" : "text-gray-800"
                  }`}
                >
                  {challenge.reward}
                </p>
              </div>
            </div>

            <div className="flex items-center">
              <Icon
                path={mdiCalendarRange}
                size={1}
                className={`${
                  darkMode ? "text-gray-400" : "text-gray-500"
                } mr-4`}
              />
              <div>
                <p
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-500"
                  }`}
                >
                  Duration
                </p>
                <p
                  className={`text-lg font-bold ${
                    darkMode ? "text-gray-200" : "text-gray-800"
                  }`}
                >
                  {challenge.startDate} - {challenge.endDate}
                </p>
              </div>
            </div>

            <div className="flex items-center">
              <Icon
                path={mdiTarget}
                size={1}
                className={`${
                  darkMode ? "text-gray-400" : "text-gray-500"
                } mr-4`}
              />
              <div>
                <p
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-500"
                  }`}
                >
                  Goal
                </p>
                <p
                  className={`text-lg font-bold ${
                    darkMode ? "text-gray-200" : "text-gray-800"
                  }`}
                >
                  {challenge.goalAmount} purchases
                </p>
              </div>
            </div>

            <div className="flex items-center">
              <Icon
                path={mdiCurrencyGbp}
                size={1}
                className={`${
                  darkMode ? "text-gray-400" : "text-gray-500"
                } mr-4`}
              />
              <div>
                <p
                  className={`text-sm ${
                    darkMode ? "text-gray-400" : "text-gray-500"
                  }`}
                >
                  Cost to Join
                </p>
                <p
                  className={`text-lg font-bold ${
                    darkMode ? "text-gray-200" : "text-gray-800"
                  }`}
                >
                  {challenge.cost} points
                </p>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <p
              className={`text-lg font-bold ${
                darkMode ? "text-gray-200" : "text-gray-800"
              } mb-2`}
            >
              Progress
            </p>
            <div
              className={`w-full ${
                darkMode ? "bg-gray-700" : "bg-gray-200"
              } rounded-full h-2.5`}
            >
              <div
                className="bg-green-600 h-2.5 rounded-full"
                style={{
                  width: `${
                    (challenge.progress / challenge.goalAmount) * 100
                  }%`,
                }}
              ></div>
            </div>
            <p
              className={`text-sm ${
                darkMode ? "text-gray-400" : "text-gray-600"
              } mt-2`}
            >
              {challenge.progress} out of {challenge.goalAmount} completed
            </p>
          </div>

          <div className="mt-8">
            {challenge.started ? (
              <Button
                label="Already Joined"
                variant="secondary"
                fullWidth
                disabled
              />
            ) : (
              <Button
                label="Join Challenge"
                onClick={async () => {
                  setIsLoading(true);
                  try {
                    const apiCall = await fetch(
                      "https://api.qmb.org.uk/startChallenge",
                      {
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: token,
                        },
                        method: "POST",
                        body: JSON.stringify({ challenge: id }),
                      }
                    );
                    console.log(apiCall);
                    if (apiCall.status != 200) {
                      throw new Error("Transaction failed!");
                    }

                    // Delay navigation to allow user to see the success message
                    setTimeout(() => {
                      setIsLoading(false);
                      navigate("/home");
                    }, 3000);
                  } catch (error) {
                    setIsLoading(false);
                  }
                }}
                variant="primary"
                fullWidth
                loading={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChallengeDetailsPage;
