import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import Icon from "@mdi/react";
import {
  mdiMenu,
  mdiTrophy,
  mdiLeaf,
  mdiAccountGroup,
  mdiChevronRight,
  mdiLogout,
  mdiCurrencyUsd,
  mdiAccountMultiplePlus,
  mdiTrendingUp,
} from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";
import { showErrorToast } from "../components/Toast";

function AdminDashboard() {
  const { darkMode, logout, token, userData, updateUserData } = useLogin();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [adminStats, setAdminStats] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchAdminStats = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://api.qmb.org.uk/getAdminStats", {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      console.log(token);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
      setAdminStats(data);
    } catch (error) {
      console.error("Failed to fetch admin stats", error);
      if (error.response) {
        if (error.response.status === 403) {
          showErrorToast(
            "Unauthorized access. Please ensure you have admin privileges."
          );
        } else {
          showErrorToast(
            `Server error: ${error.response.status}. Please try again later.`
          );
        }
      } else {
        showErrorToast(
          "Network error. Please check your connection and try again."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdminStats();
  }, [token]);

  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        try {
          await updateUserData(token);
        } catch (error) {
          console.error("Error updating user data:", error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }
  }, [token, updateUserData]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-[#319D32] flex flex-col items-center justify-center">
        <svg
          className="w-16 h-16 text-white animate-bounce"
          viewBox="0 0 24 24"
        >
          <path fill="currentColor" d={mdiLeaf} />
        </svg>
        <p className="text-white text-xl mt-4 font-righteous">
          Loading admin dashboard...
        </p>
      </div>
    );
  }

  const menuIcon = (
    <Icon
      path={mdiMenu}
      size={1.5}
      className="text-white cursor-pointer"
      aria-label="Menu"
    />
  );

  const adminOptions = [
    {
      icon: mdiTrophy,
      title: "Manage Rewards",
      description: "Add, edit, or remove rewards",
      onClick: () => navigate("/admin/rewards"),
    },
    {
      icon: mdiLeaf,
      title: "Manage Challenges",
      description: "Create and modify challenges",
      onClick: () => navigate("/admin/challenges"),
    },
    {
      icon: mdiAccountGroup,
      title: "Manage Users",
      description: "View and manage user accounts",
      onClick: () => navigate("/admin/users"),
    },
    {
      icon: mdiLogout,
      title: "Logout",
      description: "Exit admin dashboard",
      onClick: () => {
        navigate("/");
      },
    },
  ];

  const quickActions = [
    {
      icon: mdiAccountMultiplePlus,
      title: "Add New User",
      onClick: () => navigate("/admin/add-user"),
    },
    {
      icon: mdiCurrencyUsd,
      title: "Create Reward",
      onClick: () => navigate("/admin/add-reward"),
    },
    {
      icon: mdiTrendingUp,
      title: "New Challenge",
      onClick: () => navigate("/admin/add-challenge"),
    },
  ];

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900" : "bg-gray-100"
      } font-righteous pb-24 relative`}
    >
      <div className="absolute top-0 left-0 right-0 h-[10vh] bg-[#319D32] rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)] z-0"></div>
      <div className="relative z-10 container mx-auto px-4 py-4">
        <h1 className="text-2xl font-bold text-center mt-2 text-white">
          Admin Dashboard
        </h1>
      </div>

      <main className="relative z-10 container mx-auto px-4 mt-10">
        <section className="mb-8">
          <h2
            className={`text-2xl font-bold mb-4 ${
              darkMode ? "text-white" : "text-gray-800"
            }`}
          >
            Summary
          </h2>
          <div className="grid grid-cols-2 gap-4">
            {adminStats &&
              [
                { key: "totalUsers", label: "Total Users" },
                { key: "activeUsers", label: "Active Users" },
                { key: "averageGreenScore", label: "Average Green Score" },
                {
                  key: "totalMoneyTransacted",
                  label: "Total Money Transacted",
                },
                {
                  key: "challengeCompletionRate",
                  label: "Challenge Completion Rate",
                },
                { key: "popularShopItem", label: "Most Popular Shop Item" },
              ].map(({ key, label }) => (
                <div
                  key={key}
                  className={`p-4 rounded-lg ${
                    darkMode
                      ? "bg-gray-800 text-white"
                      : "bg-white text-gray-800"
                  } shadow-md`}
                >
                  <h3 className="text-lg font-semibold">{label}</h3>
                  <p className="text-2xl font-bold text-[#319D32] ">
                    {key === "averageGreenScore"
                      ? parseFloat(adminStats[key]).toFixed(2)
                      : key === "totalMoneyTransacted"
                      ? `£${(parseInt(adminStats[key]) / 100).toLocaleString(
                          undefined,
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )}` // Converting pennies to pounds and formatting as currency
                      : key === "challengeCompletionRate"
                      ? `${(parseFloat(adminStats[key]) * 100).toFixed(2)}%` // Converting to percentage
                      : key === "popularShopItem"
                      ? adminStats[key] || "N/A" // Displaying "N/A" if null
                      : adminStats[key]}
                  </p>
                </div>
              ))}
          </div>
        </section>

        <section className="mb-8">
          <h2
            className={`text-2xl font-bold mb-4 ${
              darkMode ? "text-white" : "text-gray-800"
            }`}
          >
            Quick Actions
          </h2>
          <div className="grid grid-cols-3 gap-4">
            {quickActions.map((action, index) => (
              <button
                key={index}
                onClick={action.onClick}
                className="flex flex-col items-center justify-center p-4 h-24 rounded-lg bg-[#319D32] text-white shadow-md hover:bg-[#2b8a2b] hover:shadow-lg transition-all duration-300"
              >
                <Icon path={action.icon} size={5} className="mb-2" />
                <span className="text-sm text-center font-semibold">
                  {action.title}
                </span>
              </button>
            ))}
          </div>
        </section>

        <section>
          <h2
            className={`text-2xl font-bold mb-4 ${
              darkMode ? "text-white" : "text-gray-800"
            }`}
          >
            Admin Options
          </h2>
          <nav aria-label="Admin options">
            <ul className="space-y-4 list-none p-0">
              {adminOptions.map((option, index) => (
                <li key={index}>
                  <button
                    onClick={option.onClick}
                    className={`w-full flex items-center justify-between p-4 rounded-lg ${
                      darkMode
                        ? "bg-gray-800 text-white"
                        : "bg-white text-gray-800"
                    } shadow-md hover:shadow-lg transition-shadow duration-300`}
                  >
                    <div className="flex items-center">
                      <Icon
                        path={option.icon}
                        size={1.5}
                        className="mr-4 text-[#319D32]"
                      />
                      <div className="text-left">
                        <h3 className="text-lg font-bold">{option.title}</h3>
                        <p
                          className={`text-sm ${
                            darkMode ? "text-gray-400" : "text-gray-600"
                          }`}
                        >
                          {option.description}
                        </p>
                      </div>
                    </div>
                    <Icon
                      path={mdiChevronRight}
                      size={1}
                      className={darkMode ? "text-gray-400" : "text-gray-600"}
                    />
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </section>
      </main>
    </div>
  );
}

export default AdminDashboard;
