import React from 'react';

function Toggle({ toggled, onClick, darkMode }) {
  return (
    <div
      className={`w-14 h-8 flex items-center rounded-full p-1 cursor-pointer ${
        toggled ? 'bg-green-500' : darkMode ? 'bg-gray-600' : 'bg-gray-300'
      }`}
      onClick={onClick}
    >
      <div
        className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${
          toggled ? 'translate-x-6' : ''
        }`}
      ></div>
    </div>
  );
}

export default Toggle;
