import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TopBar from "../components/TopBar";
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiAccountCircle } from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";

function PayeeDetailsPage() {
  const navigate = useNavigate();
  const { accountNumber } = useParams();
  const { token, userData, darkMode } = useLogin();

  useEffect(() => {
    if (token === null) {
      navigate("/");
    }
  }, [token, navigate]);

  const payee = useMemo(() => {
    return (
      userData.payees?.find(
        (p) => p.accountNumber.toString() === accountNumber
      ) || null
    );
  }, [userData.payees, accountNumber]);

  const transactionCount = useMemo(() => {
    return (
      userData.transactionHistory?.filter(
        (t) =>
          t.toAccount === payee?.accountNumber ||
          t.fromAccount === payee?.accountNumber
      ).length || 0
    );
  }, [userData.transactionHistory, payee]);

  const backIcon = (
    <Icon
      path={mdiArrowLeft}
      size={1.5}
      className="text-white cursor-pointer"
      onClick={() => navigate(-1)}
      aria-label="Go back"
    />
  );

  const getRagColor = (score) => {
    if (score >= 0.7) return "bg-green-500";
    if (score >= 0.5) return "bg-yellow-500";
    return "bg-red-500";
  };

  if (!payee) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900' : 'bg-gray-100'} font-righteous pb-24`}>
      <div className="bg-[#319D32] h-1/4 pt-4 pb-16 rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)]">
        <div className="container mx-auto px-4">
          <TopBar leftIcon={backIcon} />
        </div>
      </div>

      <div className="container mx-auto px-4 -mt-12">
        <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-[30px] shadow-[0_4px_2px_rgba(0,0,0,0.1)] p-6`}>
          <div className="flex items-center mb-6">
            <Icon
              path={mdiAccountCircle}
              size={3}
              className={`${darkMode ? 'text-gray-400' : 'text-gray-400'} mr-4`}
            />
            <div>
              <h2 className={`text-2xl font-bold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>{payee.name}</h2>
              <p className={`${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>{payee.accountNumber}</p>
            </div>
          </div>

          <div className="mb-6">
            <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Summary</p>
            <p className={`text-lg ${darkMode ? 'text-gray-300' : 'text-gray-800'}`}>{payee.sunmary}</p>
          </div>

          <div className="mb-6">
            <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Category</p>
            <p className={`text-lg ${darkMode ? 'text-gray-300' : 'text-gray-800'}`}>{payee.category}</p>
          </div>

          <div className="mb-6">
            <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Total Score</p>
            <div className="flex items-center">
              <div
                className={`w-4 h-4 rounded-full ${getRagColor(
                  parseFloat(payee.totalScore)
                )} mr-2`}
              ></div>
              <p className={`text-lg font-bold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                {parseFloat(payee.totalScore).toFixed(2)}
              </p>
            </div>
          </div>

          <div className="mb-6">
            <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Carbon Emissions</p>
            <p className={`text-lg font-bold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
              {payee.carbonEmissions}
            </p>
          </div>

          <div className="mb-6">
            <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Waste Management</p>
            <p className={`text-lg font-bold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
              {payee.wasteManagement}
            </p>
          </div>

          <div className="mb-6">
            <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Sustainability Practices</p>
            <p className={`text-lg font-bold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
              {payee.sustainabilityPractices}
            </p>
          </div>

          <div className="mb-6">
            <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Number of Transactions</p>
            <p className={`text-lg font-bold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
              {transactionCount}
            </p>
          </div>

          <button
            className="w-full bg-[#319D32] text-white py-3 rounded-full font-bold mt-4 hover:bg-[#2c8c2d] transition-colors"
            onClick={() =>
              navigate("/send-money", { state: { selectedPayee: payee } })
            }
          >
            Send Money to Payee
          </button>
        </div>
      </div>
    </div>
  );
}

export default PayeeDetailsPage;