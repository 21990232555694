import React from "react";
import Icon from "@mdi/react";
import { mdiMenu } from "@mdi/js";

function TopBar({ leftIcon, rightIcons, title }) {
  return (
    <div className="flex justify-between items-center px-2 py-2">
      <div className="w-8">
        {leftIcon || (
          <Icon path={mdiMenu} size={1} className="text-white cursor-pointer" />
        )}
      </div>
      {title && <h1 className="text-xl font-bold text-white">{title}</h1>}
      <div className="flex space-x-4">
        {rightIcons &&
          rightIcons.map((icon, index) => (
            <div key={index} className="w-8">
              {icon}
            </div>
          ))}
      </div>
    </div>
  );
}

export default TopBar;
