import React from "react";
import Icon from "@mdi/react";
import Toggle from "./Toggle";

function SettingsItem({ icon, label, toggled, onClick, darkMode }) {
  return (
    <div className={`flex items-center justify-between ${darkMode ? 'text-white' : 'text-gray-800'}`}>
      <div className="flex items-center">
        <Icon path={icon} size={1} className={`mr-4 ${darkMode ? 'text-gray-300' : 'text-gray-600'}`} />
        <span className="text-lg">{label}</span>
      </div>
      <Toggle toggled={toggled} onClick={onClick} darkMode={darkMode} />
    </div>
  );
}

export default SettingsItem;
