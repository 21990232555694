import React, { createContext, useState, useContext } from "react";

const CreateChallengeContext = createContext();

export const useCreateChallenge = () => useContext(CreateChallengeContext);

export const CreateChallengeProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    type: "numberOfItems",
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    category: "",
    rewardID: "",
    cost: 0,
    goalAmount: 0,
  });

  const [showRewardModal, setShowRewardModal] = useState(false);

  const updateFormData = (newData) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  const resetFormData = () => {
    setFormData({
      type: "",
      title: "",
      description: "",
      startDate: "",
      endDate: "",
      category: "",
      rewardID: "",
      cost: "",
      goalAmount: "",
    });
  };

  return (
    <CreateChallengeContext.Provider
      value={{
        formData,
        updateFormData,
        resetFormData,
        showRewardModal,
        setShowRewardModal,
      }}
    >
      {children}
    </CreateChallengeContext.Provider>
  );
};
