import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiMagnify } from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";
import Navbar from "../components/Navbar";
import Button from "../components/Button";

function UserManagement() {
  const { darkMode, token } = useLogin();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Fetch users from API
    const fetchUsers = async () => {
      try {
        const response = await fetch("https://api.qmb.org.uk/admin/users", {
          headers: {
            Authorization: token,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUsers(data.users);
        } else {
          console.error("Failed to fetch users");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [token]);

  const backIcon = (
    <Icon
      path={mdiArrowLeft}
      size={1.5}
      className="text-white cursor-pointer"
      onClick={() => navigate(-1)}
    />
  );

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.accountNumber.toString().includes(searchTerm)
  );

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900" : "bg-gray-100"
      } font-righteous pb-24 relative`}
    >
      <div className="absolute top-0 left-0 right-0 h-1/4 bg-[#319D32] rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)] z-0"></div>
      <div className="relative z-10 container mx-auto px-4 py-4">
        <TopBar leftIcon={backIcon} />
        <h1 className="text-3xl font-bold mt-4 text-white text-center">
          Manage Users
        </h1>
      </div>

      <main className="relative z-10 container mx-auto px-4 mt-12">
        <div
          className={`flex items-center mb-4 ${
            darkMode ? "bg-gray-800" : "bg-white"
          } rounded-lg p-2`}
        >
          <Icon
            path={mdiMagnify}
            size={1}
            className={`mr-2 ${darkMode ? "text-gray-400" : "text-gray-600"}`}
          />
          <input
            type="text"
            placeholder="Search users..."
            className={`w-full bg-transparent outline-none ${
              darkMode ? "text-white" : "text-gray-800"
            }`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <ul className="space-y-4">
          {filteredUsers.map((user) => (
            <li
              key={user.accountNumber}
              className={`${
                darkMode ? "bg-gray-800" : "bg-white"
              } rounded-lg p-4 shadow`}
            >
              <div className="flex justify-between items-center">
                <div>
                  <h3
                    className={`text-lg font-bold ${
                      darkMode ? "text-white" : "text-gray-800"
                    }`}
                  >
                    {user.name}
                  </h3>
                  <p
                    className={`text-sm ${
                      darkMode ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    Account: {user.accountNumber}
                  </p>
                </div>
                <Button
                  onClick={() => {
                    /* Implement view user details */
                  }}
                  variant="secondary"
                >
                  View Details
                </Button>
              </div>
            </li>
          ))}
        </ul>
      </main>
    </div>
  );
}

export default UserManagement;
