import React from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button";

function TermsPage() {
  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
          
          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">1. Acceptance of Terms</h2>
            <p>By using Verdant Pay, you agree to these terms. Please read them carefully.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">2. Description of Service</h2>
            <p>Verdant Pay is a digital payment platform that allows users to send and receive money.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">3. User Responsibilities</h2>
            <p>You are responsible for maintaining the confidentiality of your account information.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">4. Privacy Policy</h2>
            <p>Your use of Verdant Pay is also governed by our Privacy Policy.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">5. Modifications to Service</h2>
            <p>We reserve the right to modify or discontinue the service at any time.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">6. Limitation of Liability</h2>
            <p>Verdant Pay is not liable for any indirect, incidental, or consequential damages.</p>
          </section>

          <div className="mt-8 flex justify-center">
            <Link to="/register">
              <Button label="Back to Registration" variant="secondary" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsPage;
