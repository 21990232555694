import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import Icon from "@mdi/react";
import {
  mdiMenu,
  mdiCart,
  mdiTrophy,
  mdiShieldStar,
  mdiAccountStar,
  mdiPackageVariant,
  mdiLeaf,
} from "@mdi/js";
import { useLogin } from "../contexts/LoginContext";
import Navbar from "../components/Navbar";
import Button from "../components/Button";
import { showErrorToast, showSuccessToast } from "../components/Toast";

function ShopPage() {
  const { userData, updateUserData, darkMode, token } = useLogin();
  const navigate = useNavigate();

  const shopItems = useMemo(() => {
    console.log(userData.shopItems);
    return (userData.shopItems || []).filter((item) => item.cost > 0);
  }, [userData.shopItems]);

  const handlePurchase = async (item) => {
    try {
      const response = await fetch("https://api.qmb.org.uk/buyItem", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ item: item.rewardID }),
      });

      if (response.ok) {
        showSuccessToast(`Successfully purchased ${item.rewardTitle}`);
      }
    } catch (error) {
      showSuccessToast(`Successfully purchased ${item.rewardTitle}`);
    }
  };

  const menuIcon = (
    <Icon
      path={mdiMenu}
      size={1.5}
      className="text-white cursor-pointer"
      aria-label="Menu"
    />
  );

  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900" : "bg-gray-100"
      } font-righteous pb-24 relative`}
    >
      <div className="absolute top-0 left-0 right-0 h-[15vh] bg-[#319D32] rounded-b-[60px] shadow-[0_4px_2px_rgba(0,0,0,0.3)] z-0"></div>
      <div className="relative z-10 container mx-auto px-4 py-4">
        <h1 className="text-3xl font-bold mt-4 text-white text-center">Shop</h1>
      </div>

      <main className="relative z-10 container mx-auto px-4 mt-20">
        {shopItems.length === 0 ? (
          <p
            className={`text-center ${
              darkMode ? "text-gray-400" : "text-gray-600"
            }`}
          >
            No items available in the shop at the moment.
          </p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {shopItems.map((item) => (
              <div
                key={item.rewardID}
                className={`${
                  darkMode ? "bg-gray-800" : "bg-white"
                } rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105`}
              >
                <div
                  className={`p-4 ${getRewardTypeColor(
                    item.rewardType
                  )} flex justify-between items-center`}
                >
                  <Icon
                    path={getRewardTypeIcon(item.rewardType)}
                    size={1.5}
                    color="white"
                  />
                  <span className="text-white font-bold">
                    {item.rewardType}
                  </span>
                </div>
                <div className="p-4">
                  <h3
                    className={`text-xl font-bold mb-1 ${
                      darkMode ? "text-white" : "text-gray-800"
                    }`}
                  >
                    {item.rewardTitle}
                  </h3>
                  {item.rewardText && (
                    <p
                      className={`text-sm mb-3 ${
                        darkMode ? "text-gray-400" : "text-gray-600"
                      }`}
                    >
                      {item.rewardText}
                    </p>
                  )}
                  <div className="space-y-2 mb-4">
                    {item.rewardQuantity && (
                      <p
                        className={`${
                          darkMode ? "text-gray-300" : "text-gray-600"
                        }`}
                      >
                        <Icon
                          path={mdiPackageVariant}
                          size={0.8}
                          className="inline mr-2"
                        />
                        Quantity: {item.rewardQuantity}
                      </p>
                    )}
                    <p
                      className={`${
                        darkMode ? "text-gray-300" : "text-gray-600"
                      }`}
                    >
                      <Icon
                        path={mdiTrophy}
                        size={0.8}
                        className="inline mr-2"
                      />
                      Minimum Level: {item.minimumLevel}
                    </p>
                  </div>
                  <div className="flex justify-between items-center">
                    <span
                      className={`text-lg font-bold ${
                        darkMode ? "text-green-400" : "text-green-600"
                      }`}
                    >
                      <Icon path={mdiLeaf} size={1} className="inline mr-1" />
                      {item.cost} points
                    </span>
                    <Button
                      onClick={() => handlePurchase(item)}
                      variant="primary"
                      disabled={
                        userData.balance < item.cost ||
                        userData.level < item.minimumLevel
                      }
                      className="flex items-center"
                      label="Purchase"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </main>

      <Navbar />
    </div>
  );
}

function getRewardTypeColor(type) {
  switch (type.toLowerCase()) {
    case "title":
      return "bg-blue-500";
    case "badge":
      return "bg-blue-500";
    default:
      return "bg-gray-500";
  }
}

function getRewardTypeIcon(type) {
  switch (type.toLowerCase()) {
    case "title":
      return mdiAccountStar;
    case "badge":
      return mdiShieldStar;
    default:
      return mdiTrophy;
  }
}

export default ShopPage;
